define("granite/utils/resolve-for-type-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = resolveForTypeKey;

  var parsedName = function parsedName(s) {
    return s ? s.replace(/([A-Z])/g, function ($1, p1, pos) {
      return (pos > 0 ? '-' : '') + $1.toLowerCase();
    }) : s;
  };

  function resolveForTypeKey(key, iKey) {
    var typeKey = iKey ? key : key + 'Type',
        idKey = iKey ? iKey : key + 'Id';
    return Ember.computed(typeKey, idKey, function () {
      var type = parsedName(this.get(typeKey)),
          id = this.get(idKey);
      return type && id ? this.store.find(type, id) : undefined;
    });
  }
});