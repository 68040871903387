define("granite/models/manual-applicant-source", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    name: attr('string'),
    description: attr('string'),
    costPerDay: attr('number'),
    company: belongsTo('company'),
    creator: belongsTo('employee'),
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });

  _exports.default = _default;
});