define("granite/components/intro-js/step", ["exports", "ember-introjs/components/step"], function (_exports, _step) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _step.default;
    }
  });
});