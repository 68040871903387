define("granite/templates/account/anatomy/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xQP9dkqf",
    "block": "{\"symbols\":[\"execute\",\"mapper\",\"employee\"],\"statements\":[[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"ui header left floated\"],[8],[0,\"\\n    Company Anatomy\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"basenode-selection\"],[8],[0,\"Select a supervisor\"],[9],[0,\"\\n    \"],[5,\"ui-dropdown\",[],[[\"@id\",\"@class\",\"@selected\",\"@forceSelection\",\"@onChange\"],[\"basenode-selection\",\"search selection\",[23,0,[\"selectedNode\"]],false,[28,\"set\",[[23,0,[]],\"selectedNode\",[28,\"-set-placeholder\",null,null]],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"default text\"],[8],[0,\"Select an employee\"],[9],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"item\"],[11,\"data-value\",[28,\"map-value\",[[23,2,[]],[23,3,[]]],null]],[8],[0,\"\\n            \"],[1,[23,3,[\"fullName\"]],false],[4,\"if\",[[23,3,[\"jobTitle\"]]],null,{\"statements\":[[0,\" - \"],[7,\"span\",true],[10,\"class\",\"light text\"],[8],[1,[23,3,[\"jobTitle\"]],false],[9]],\"parameters\":[]},null],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"],[9],[0,\"\\n    \"]],\"parameters\":[1,2]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"baseNode\"]]],null,{\"statements\":[[0,\"  \"],[5,\"visualizations/organization-tree\",[],[[\"@baseNode\"],[[23,0,[\"baseNode\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/anatomy/index.hbs"
    }
  });

  _exports.default = _default;
});