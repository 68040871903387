define("granite/templates/account/settings/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wroUjnCA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  User Settings\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n  Customize settings for your user account\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n\"],[7,\"form\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"current-password\"],[8],[0,\"Current Password\"],[9],[0,\"\\n    \"],[5,\"input\",[[12,\"placeholder\",\"Enter your current password\"]],[[\"@type\",\"@id\",\"@value\"],[\"password\",\"current-password\",[23,0,[\"passwords\",\"currentPassword\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"new-password\"],[8],[0,\"New Password\"],[9],[0,\"\\n    \"],[5,\"input\",[[12,\"placeholder\",\"Enter your new password\"]],[[\"@type\",\"@id\",\"@value\"],[\"password\",\"new-password\",[23,0,[\"passwords\",\"newPassword\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"confirm-password\"],[8],[0,\"Confirm New Password\"],[9],[0,\"\\n    \"],[5,\"input\",[[12,\"placeholder\",\"Confirm your new password\"]],[[\"@type\",\"@id\",\"@value\"],[\"password\",\"confirm-password\",[23,0,[\"passwords\",\"confirmPassword\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\"],[7,\"form\",false],[3,\"on\",[\"submit\",[28,\"prevent-default\",[[23,0,[\"change\"]]],null]]],[8],[0,\"\\n  \"],[5,\"form/action-button\",[[12,\"class\",\"ui huge fluid yellow button\"]],[[\"@loading\"],[[23,0,[\"data\",\"statuses\",\"working\",\"isLoading\"]]]],{\"statements\":[[0,\"Change Password\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/settings/user.hbs"
    }
  });

  _exports.default = _default;
});