define("granite/templates/account/job-opening/setup/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bRkkW4zm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"ui header\"],[8],[0,\"Setup will take you through important steps to start hiring for \"],[1,[23,0,[\"model\",\"job\",\"title\"]],false],[0,\". Here are the things to watch for:\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n\"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"settings icon\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    Settings\\n    \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n      Recruiting campaigns are configurable and can be set up how you prefer. Settings allow you to configure the way notifications, talent pools, and other aspects of the campaign function.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"announcement icon\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    Sources\\n    \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n      Sources manage where your compaign will be distributed. We offer several automated integrations with popular job boards, but you may also use manual sources that Granite cannot manage automatically. Utilizing manual and automated sources will allow you to keep track of where the job is posted throughout the hiring process. Automated sources will automatically post and take down postings when you start and end the campaign, respectively.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n\"],[7,\"button\",false],[12,\"class\",\"ui huge positive fluid button\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"route-action\",[\"startSetup\"],null]]],[8],[0,\"Let's get started\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/job-opening/setup/index.hbs"
    }
  });

  _exports.default = _default;
});