define("granite/helpers/mut-select", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mutSelect = mutSelect;
  _exports.default = void 0;

  function mutSelect(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        obj = _ref2[0],
        path = _ref2[1];

    return function (component, id, value) {
      Ember.set(obj, path, value);
    };
  }

  var _default = Ember.Helper.helper(mutSelect);

  _exports.default = _default;
});