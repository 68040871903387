define("granite/templates/account/employee/index/counseling/issue/corrective-action/print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PXpvhbfb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"print-media__corrective-action\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"ui center aligned header\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"await\",[[23,0,[\"model\",\"actionSeverity\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"get\",[[28,\"await\",[[23,0,[\"model\",\"actionSeverity\"]]],null],\"name\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item clearfix\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"left floated content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n        Employee: \"],[1,[23,0,[\"model\",\"employee\",\"fullName\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"meta\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"employee\",\"jobTitle\"]]],null,{\"statements\":[[0,\"          \"],[1,[23,0,[\"model\",\"employee\",\"jobTitle\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"right floated content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n        Date: \"],[1,[28,\"time\",[[23,0,[\"model\",\"issuedOn\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"meta\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"employee\",\"department\",\"name\"]]],null,{\"statements\":[[0,\"          Department: \"],[1,[23,0,[\"model\",\"employee\",\"department\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"display/formal-action\",[],[[\"@correctiveAction\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/counseling/issue/corrective-action/print.hbs"
    }
  });

  _exports.default = _default;
});