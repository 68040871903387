define("granite/templates/account/benefits/plans/integrations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5uVfPYyI",
    "block": "{\"symbols\":[\"carrier\"],\"statements\":[[7,\"h3\",true],[10,\"class\",\"ui header\"],[8],[0,\"Setup\"],[9],[0,\"\\n\"],[7,\"h4\",true],[10,\"class\",\"center aligned text\"],[8],[0,\"\\n  Select a carrier\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui four stackable cards carrier-cards\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui card carrier-card\"],[8],[0,\"\\n      \"],[5,\"ember-tooltip\",[[12,\"side\",\"right\"],[12,\"container\",\"body\"]],[[],[]],{\"statements\":[[0,\"\\n        Link your benefits with \"],[1,[23,1,[\"name\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"account.benefits.plans.integrations.carrier-link\",[23,1,[\"key\"]]]],{\"statements\":[[0,\"\\n          \"],[7,\"img\",true],[11,\"alt\",[29,[\"Carrier: \",[23,1,[\"name\"]]]]],[11,\"src\",[23,1,[\"logo\"]]],[8],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui card carrier-card carrier-card--other\"],[8],[0,\"\\n    \"],[5,\"ember-tooltip\",[],[[\"@side\",\"@container\"],[\"right\",\"body\"]],{\"statements\":[[0,\"\\n      If you don’t see the carrier you are with here, they are not supported in Granite. You must manually add plans, and request an EDI file download or print when you need to submit enrollments.\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content center aligned text\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[0,\"Other\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/benefits/plans/integrations/index.hbs"
    }
  });

  _exports.default = _default;
});