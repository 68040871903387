define("granite/utils/object-array-is-dirty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectArrayIsDirty;

  function objectArrayIsDirty() {
    var a = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var b = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'name';
    var isDirty = a.length !== b.length || a.some(function (ax) {
      var matchVal = Ember.get(ax, key),
          bitm = b.find(function (bx) {
        return matchVal === Ember.get(bx, key);
      });

      if (!bitm) {
        return true;
      }

      return Object.keys(ax).some(function (axProp) {
        return Ember.get(bitm, axProp) !== Ember.get(ax, axProp);
      }) || Object.keys(bitm).some(function (bxProp) {
        return Ember.get(bitm, bxProp) !== Ember.get(ax, bxProp);
      });
    });
    return isDirty;
  }
});