define("granite/models/validations/form-element", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberCpValidations.buildValidations)({
    label: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '"Question" is required for questions'
    })],
    type: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: '"Answer Type" is required for questions'
    })]
  });

  _exports.default = _default;
});