define("granite/mixins/route-abstractions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    auth: Ember.inject.service(),
    model: function model(params) {
      console.warn('Mixing in the route-abstraction for edit is deprecated in favor of extending the granite core route.'); // eslint-disable-line

      if (this.bypassModelHook) {
        return this._super.apply(this, arguments);
      }

      var modelName = this.modelName,
          segmentKey = this.segmentKey;
      (false && !(modelName) && Ember.assert('You must specify a modelName.', modelName));
      return this.store.find(modelName, params[segmentKey || 'id']);
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.auth.isExpired) {
          return true;
        }

        var model = this.controller.get('model'),
            hasChangedAttributes = Object.keys(model.changedAttributes()).length > 0;

        if (hasChangedAttributes && !confirm('Are you sure you want to abandon progress on this page?')) {
          transition.abort();
        } else {
          if (hasChangedAttributes) {
            model.rollbackAttributes();
          }

          return true;
        }
      }
    }
  });

  _exports.default = _default;
});