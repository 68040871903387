define("granite/templates/account/employee/index/education/training-assignment/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YLXzOROK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui two column grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui column\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[8],[0,\"Notes:\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[23,0,[\"model\",\"note\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui column right aligned text\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"dueDate\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[11,\"class\",[28,\"if\",[[23,0,[\"pastDue\"]],\"text-danger\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"pastDue\"]]],null,{\"statements\":[[0,\"          Past Due:\\n          \"],[7,\"small\",true],[8],[1,[28,\"time\",[[23,0,[\"model\",\"dueDate\"]],\"MM/DD/YY\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          Due Date:\\n          \"],[7,\"small\",true],[10,\"class\",\"light text\"],[8],[1,[28,\"time\",[[23,0,[\"model\",\"dueDate\"]],\"MM/DD/YY\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    Status:\\n    \"],[7,\"small\",true],[10,\"class\",\"light text\"],[8],[1,[23,0,[\"model\",\"status\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/education/training-assignment/index.hbs"
    }
  });

  _exports.default = _default;
});