define("granite/templates/account/employee/index/education/certification/renewal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bGNqlV9y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"ui header titlecased text\"],[8],[0,\"\\n  \"],[1,[28,\"if\",[[23,0,[\"renewal\"]],\"Editing\",\"Adding\"],null],false],[0,\" Renewal For \"],[1,[23,0,[\"model\",\"certification\",\"name\"]],false],[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"right floated\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"text-danger\"]],[[\"@route\",\"@model\"],[\"account.employee.index.education.certification\",[23,0,[\"model\",\"certification\"]]]],{\"statements\":[[0,\"\\n      Cancel\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n\"],[5,\"quick-form\",[],[[\"@form\",\"@model\",\"@controller\",\"@submitClass\",\"@onsubmit\"],[[23,0,[\"currentForm\",\"elements\"]],[23,0,[\"model\",\"renewal\"]],[23,0,[]],\"large green fluid\",[23,0,[\"saveRenewal\"]]]],{\"statements\":[[0,\"\\n  \"],[4,\"if\",[[23,0,[\"renewal\"]]],null,{\"statements\":[[0,\"Save Changes\"]],\"parameters\":[]},{\"statements\":[[0,\"Add Renewal\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/education/certification/renewal.hbs"
    }
  });

  _exports.default = _default;
});