define("granite/serializers/activity", ["exports", "ember-data", "granite/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      comments: {
        embedded: 'always'
      },
      likes: {
        embedded: 'always'
      }
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var isNull = function isNull(val) {
        return !val || val === '';
      };

      if (isNull(json.targetId)) {
        delete json.targetId;
      }

      if (isNull(json.actorId)) {
        delete json.actorId;
      }

      if (!json.likes.length) {
        json.likes = null;
      }

      return json;
    }
  });

  _exports.default = _default;
});