define("granite/models/event", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "granite/utils/resolve-for-type-key"], function (_exports, _model, _attr, _relationships, _resolveForTypeKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    start: (0, _attr.default)('date'),
    end: (0, _attr.default)('date'),
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    notes: (0, _attr.default)('string'),
    company: (0, _relationships.belongsTo)('company', {
      inverse: null
    }),
    creator: (0, _relationships.belongsTo)('employee', {
      inverse: null
    }),
    organizer: (0, _relationships.belongsTo)('employee', {
      inverse: null
    }),
    facilitator: (0, _relationships.belongsTo)('employee', {
      inverse: null
    }),
    attendees: (0, _relationships.hasMany)('employee'),
    attendantType: (0, _attr.default)('string'),
    attendantId: (0, _attr.default)('string'),
    contextType: (0, _attr.default)('string'),
    contextId: (0, _attr.default)('string'),
    skipAttendeeNotification: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    created: {
      type: Date,
      default: Date.now,
      index: true
    },
    attendant: (0, _resolveForTypeKey.default)('attendant'),
    context: (0, _resolveForTypeKey.default)('context')
  });

  _exports.default = _default;
});