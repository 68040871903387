define("granite/core/abstractions/routes/index", ["exports", "granite/core/abstractions/routes/add", "granite/core/abstractions/routes/edit"], function (_exports, _add, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    add: _add.default,
    edit: _edit.default
  };
  _exports.default = _default;
});