define("granite/templates/account/benefits/plans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "adLRKyni",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui very padded raised segment animated fadeInUp\"],[8],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui header\"],[8],[0,\"Plans\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/benefits/plans.hbs"
    }
  });

  _exports.default = _default;
});