define("granite/helpers/-set-placeholder", ["exports", "ember-set-helper/helpers/-set-placeholder"], function (_exports, _setPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _setPlaceholder.default;
    }
  });
});