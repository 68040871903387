define("granite/models/applicant", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    fullName: Ember.computed('firstName', 'lastName', 'middleInitial', 'suffix', function () {
      var n = this.getProperties('firstName', 'lastName', 'middleName', 'suffixName'),
          fullName = '';
      fullName += n.firstName || '';
      fullName += n.middleName ? ' ' + n.middleName : '';
      fullName += n.lastName ? ' ' + n.lastName : '';
      fullName += n.suffixName ? ' ' + n.suffixName : '';
      return fullName.length > 0 ? fullName : undefined;
    }),
    firstName: (0, _attr.default)('string'),
    middleName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    suffixName: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    created: {
      type: Date,
      default: Date.now,
      index: true
    }
  });

  _exports.default = _default;
});