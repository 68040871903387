define("granite/templates/account/employees/forms/responses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FH79J3mI",
    "block": "{\"symbols\":[\"openResponseView\",\"response\"],\"statements\":[[7,\"h3\",true],[8],[1,[28,\"or\",[[23,0,[\"form\",\"name\"]],[23,0,[\"form\",\"title\"]]],null],false],[0,\" Responses\"],[9],[0,\"\\n\\n\"],[5,\"modals/view-form-response\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui relaxed list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"ui item\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n            Response by \"],[1,[23,2,[\"employee\",\"fullName\"]],false],[0,\" on \"],[1,[28,\"time\",[[23,2,[\"submittedOn\"]],\"M/D/YY [at] h:mma\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"extra\"],[8],[0,\"\\n            \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"on\",[\"click\",[28,\"prevent-default\",[[28,\"fn\",[[23,1,[]],[23,2,[]],[23,0,[\"form\"]]],null]],null]]],[8],[0,\"View Responses\"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[5,\"elements/x-pagination\",[],[[\"@page\",\"@model\",\"@limit\",\"@onChange\"],[[23,0,[\"page\"]],[23,0,[\"model\"]],[23,0,[\"limit\"]],[28,\"set\",[[23,0,[]],\"page\",[28,\"-set-placeholder\",null,null]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employees/forms/responses.hbs"
    }
  });

  _exports.default = _default;
});