define("granite/templates/account/benefits/plans/integrations/carrier-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KgD1D3bW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"ui header\"],[8],[0,\"Setup\"],[9],[0,\"\\n\"],[7,\"form\",false],[12,\"class\",\"ui form\"],[3,\"on\",[\"submit\",[28,\"prevent-default\",[[23,0,[\"linkCarrier\"]]],null]]],[8],[0,\"\\n  \"],[1,[28,\"component\",[[23,0,[\"carrierRoute\"]]],[[\"onDataUpdate\",\"onValidityChange\"],[[28,\"set\",[[23,0,[]],\"carrierData\",[28,\"-set-placeholder\",null,null]],null],[28,\"set\",[[23,0,[]],\"carrierDataIsValid\",[28,\"-set-placeholder\",null,null]],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[5,\"form/action-button\",[[12,\"class\",\"ui massive fluid green button\"],[12,\"type\",\"submit\"]],[[\"@disabled\",\"@loading\"],[[28,\"not\",[[23,0,[\"carrierDataIsValid\"]]],null],[23,0,[\"data\",\"statuses\",\"carrierLink\",\"isLoading\"]]]],{\"statements\":[[0,\"\\n    Complete Carrier Link\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/benefits/plans/integrations/carrier-link.hbs"
    }
  });

  _exports.default = _default;
});