define("granite/templates/account/documents/canned", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/hZ95wM8",
    "block": "{\"symbols\":[\"assignment\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui container basic segment\"],[8],[0,\"\\n  \"],[5,\"ui-menu\",[],[[\"@menuClass\"],[\"pointing\"]],{\"statements\":[[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.documents\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"list icon\"],[8],[9],[0,\"Summary\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix\"],[8],[0,\"\\n    \"],[7,\"hgroup\",true],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[0,\"\\n        \"],[5,\"display/icon-for-file\",[],[[\"@canned\"],[true]]],[0,\"\\n        \"],[1,[23,0,[\"model\",\"shortTitle\"]],false],[0,\"\\n\"],[4,\"if\",[[23,0,[\"url\"]]],null,{\"statements\":[[0,\"          \"],[7,\"a\",true],[11,\"href\",[23,0,[\"url\"]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[10,\"class\",\"right floated\"],[10,\"download\",\"\"],[8],[7,\"i\",true],[10,\"class\",\"download fitted icon\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n        \"],[7,\"h4\",true],[8],[1,[23,0,[\"model\",\"title\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"Assignees\"],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"fileAssignments\"]]],null,{\"statements\":[[0,\"        \"],[5,\"list-item/file-assignment\",[],[[\"@assignment\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        No Assignees Found\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/documents/canned.hbs"
    }
  });

  _exports.default = _default;
});