define("granite/templates/account/employee/onboard-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dXPCTemk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised very padded text segment container animation__complete-pop\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"title\"],[8],[7,\"i\",true],[10,\"class\",\"thumbs up icon\"],[8],[9],[0,\" Great work! \"],[1,[23,0,[\"model\",\"firstName\"]],false],[0,\" is ready to go.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/onboard-complete.hbs"
    }
  });

  _exports.default = _default;
});