define("granite/utils/expand-serialized-object", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeObject;

  function _findMatchingArg(key, args) {
    var matchingArg;

    for (var i = 0; i < args.length; i++) {
      var pathPrefix = args[i];

      if (key.indexOf(pathPrefix) > -1) {
        matchingArg = pathPrefix;
        break;
      }
    }

    return matchingArg;
  }

  function _lowerCaseFirst(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  function _remapKeys(object, newKey, matchingKeys) {
    object[newKey] = {};
    matchingKeys.forEach(function (oldKey) {
      object[newKey][_lowerCaseFirst(oldKey.replace(newKey, ''))] = object[oldKey];
      delete object[oldKey];
    });
  }

  function serializeObject(object) {
    var args = Array.prototype.slice.call(arguments, 1);

    if (!object || (0, _typeof2.default)(object) !== 'object') {
      return object;
    }

    var objectKeys = Object.keys(object);
    objectKeys.forEach(function (key) {
      var matchingArg = _findMatchingArg(key, args);

      if (!matchingArg) {
        return;
      }

      args.splice(args.indexOf(matchingArg), 1);
      var matchingKeys = objectKeys.filter(function (_key) {
        return _key.indexOf(matchingArg) > -1;
      });

      _remapKeys(object, matchingArg, matchingKeys);
    });
    return object;
  }
});