define("granite/models/validations/form", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberCpValidations.buildValidations)({
    elements: (0, _emberCpValidations.validator)('has-many')
  });

  _exports.default = _default;
});