define("granite/templates/account/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rDUUhcgj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container import\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"sign in alternate icon\"],[8],[9],[0,\" Import Records\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/import.hbs"
    }
  });

  _exports.default = _default;
});