define("granite/mixins/wizard/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    application: Ember.inject.controller(),
    // steps: computed.reads('target.steps'),
    // basePath: computed.reads('target.basePath'),
    currentStep: Ember.computed('steps.@each.link', 'application.currentPath', function () {
      var steps = this.steps,
          pathSegment = this.get('application.currentPath').split('.').pop();
      return steps.find(function (step) {
        return pathSegment.indexOf(step.link) > -1;
      });
    }),
    currentStepIndex: Ember.computed('currentStep', 'steps', function () {
      return this.steps.indexOf(this.currentStep) + 1;
    }),
    nextStep: Ember.computed('steps', 'currentStepIndex', function () {
      return this.steps.objectAt(this.currentStepIndex);
    }),
    progress: Ember.computed('currentStepIndex', 'steps', function () {
      return this.currentStepIndex / this.get('steps.length') * 100;
    })
  });

  _exports.default = _default;
});