define("granite/templates/account/recruiting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gM614D8C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui pointing menu\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.recruiting.index\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"user plus icon\"],[8],[9],[0,\"Campaigns\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.recruiting.job-descriptions.index\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"list icon\"],[8],[9],[0,\"Descriptions\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/recruiting.hbs"
    }
  });

  _exports.default = _default;
});