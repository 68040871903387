define("granite/templates/account/education/webinars/stream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zJ5xBxGd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container page__webinars\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui basic segment\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"Watch: \"],[1,[23,0,[\"model\",\"authorization\",\"webinar\",\"title\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n    \"],[5,\"videojs-player\",[],[[\"@src\",\"@type\",\"@autoplay\",\"@fluid\"],[[23,0,[\"model\",\"playback\",\"url\"]],\"video/mp4\",true,true]]],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"ui green fluid button hidden-print\"],[12,\"role\",\"button\"]],[[\"@route\"],[\"account.education.webinars.index\"]],{\"statements\":[[0,\"\\n      Back to webinars\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/education/webinars/stream.hbs"
    }
  });

  _exports.default = _default;
});