define("granite/templates/account/employee/index/counseling/issue/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MXQdzStM",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[[12,\"class\",\"ui red fluid button\"]],[[\"@route\"],[\"account.employee.index.counseling.issue.new\"]],{\"statements\":[[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"bullseye icon\"],[8],[9],[0,\"Issue a new corrective action\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n\"],[5,\"display/resource-list\",[[12,\"class\",\"ui divided link items\"]],[[\"@model\",\"@itemComponent\",\"@linkTo\",\"@linkClass\"],[[23,0,[\"model\"]],\"list-item/employee-corrective-action-item\",\"account.employee.index.counseling.issue.corrective-action\",\"item\"]]],[0,\"\\n\\n\"],[5,\"elements/x-pagination\",[],[[\"@page\",\"@model\",\"@limit\",\"@onChange\"],[[23,0,[\"page\"]],[23,0,[\"model\"]],[23,0,[\"limit\"]],[28,\"set\",[[23,0,[]],\"page\",[28,\"-set-placeholder\",null,null]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/counseling/issue/index.hbs"
    }
  });

  _exports.default = _default;
});