define("granite/components/videojs-player", ["exports", "ember-videojs/components/videojs-player"], function (_exports, _videojsPlayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _videojsPlayer.default;
    }
  });
});