define("granite/mixins/file-handling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /* Make a preflight request to create a file before upload. Use this if you are
    using the file api as-is, without additional middleware. */
    filePreflight: true,

    /* Base endpoint path for post preflight request - replaces fileEndpoint var with
    parsed endpoint */
    fileBaseEndpoint: '/api/v1/file/:id',
    // initialize
    fileIsAdded: false,
    dropzoneId: 'dropzone',
    // use your own!
    fileEndpoint: Ember.computed('fileBaseEndpoint', 'filePreflightIdentifier', function () {
      var fileBaseEndpoint = this.fileBaseEndpoint,
          preflightId = this.filePreflightIdentifier;
      return fileBaseEndpoint.replace(':id', preflightId);
    }),
    __ajaxStart: function __ajaxStart() {
      if (this.ajaxStart) {
        this.ajaxStart.apply(this, arguments);
      }
    },
    __ajaxSuccess: function __ajaxSuccess() {
      if (this._resolveProcess) {
        this._resolveProcess(this.__fileModel);
      }

      if (this.ajaxSuccess) {
        this.ajaxSuccess.apply(this, arguments);
      }
    },
    __ajaxError: function __ajaxError() {
      if (this._rejectProcess) {
        this._rejectProcess.apply(this, arguments);
      }

      if (this.ajaxError) {
        this.ajaxError.apply(this, arguments);
      }
    },
    __doPreflight: function __doPreflight() {
      var _this = this;

      var fileData = this.fileData;
      return this.store.createRecord('file', fileData).save().then(function () {
        var file = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        if (file && file.get('id')) {
          _this.set('__fileModel', file);

          _this.set('filePreflightIdentifier', file.get('id'));
        }

        return file;
      });
    },
    processQueue: function processQueue() {
      var DZ = Dropzone.forElement("#".concat(this.dropzoneId)),
          calculatedUrl = this.fileEndpoint;
      DZ.options.url = calculatedUrl;
      return DZ.processQueue();
    },
    upload: function upload() {
      var _this2 = this;

      this.__ajaxStart();

      this.set('_processingPromise', new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.set('_resolveProcess', resolve);

        _this2.set('_rejectProcess', reject); // do a preflight request


        if (_this2.filePreflight) {
          return _this2.__doPreflight().then(function () {
            return Ember.run.next(function () {
              _this2.processQueue();
            });
          }).catch(_this2.__ajaxError.bind(_this2));
        }

        return _this2.processQueue();
      }));
      return this._processingPromise;
    },
    actions: {
      addedFile: function addedFile(file) {
        this.setProperties({
          __dropzone: this,
          __file: file,
          fileIsAdded: true
        });
      },
      upload: function upload() {
        this.upload();
      },
      uploadedFile: function uploadedFile(dzfile, response) {
        this.__fileModel.setProperties(response.file);

        Dropzone.forElement("#".concat(this.dropzoneId)).removeAllFiles(dzfile);

        this.__ajaxSuccess(null, true);

        if (this.uploadComplete) {
          var fileId = response.file._id;
          this.store.pushPayload('file', {
            file: [response.file]
          });
          this.uploadComplete(this.store.peekRecord('file', fileId));
        }
      },
      removeFile: function removeFile(file) {
        Dropzone.forElement("#".concat(this.dropzoneId)).removeAllFiles(file);
        this.set('fileIsAdded', false);
      }
    }
  });

  _exports.default = _default;
});