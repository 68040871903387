define("granite/utils/title-case", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = titleCase;

  function titleCase(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        value = _ref2[0];

    return value && typeof value === 'string' ? value.replace(/\w\S*/g, function (str) {
      return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
    }) : value;
  }
});