define("granite/mixins/modal-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    openModal: function openModal(id, key) {
      var _this = this;

      Ember.$("#".concat(id)).modal({
        detachable: true,
        context: '.ember-application',
        onHidden: function onHidden() {
          if (!_this.get("".concat(key, "Responded"))) {
            _this.get("".concat(key, "Promise.reject"))();
          }
        }
      }).modal('show');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _this.set("".concat(key, "Promise"), {
          resolve: resolve,
          reject: reject
        });
      });
    },
    modalResponse: function modalResponse(prefix, response) {
      var promise = this.get("".concat(prefix, "Promise"));
      this.set("".concat(prefix, "Responded"), true);
      return promise[response ? 'resolve' : 'reject'](response);
    },
    actions: {
      openModal: function openModal() {
        this.openModal.apply(this, arguments);
      },
      modalResponse: function modalResponse() {
        this.modalResponse.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});