define("granite/config/forms/lists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lists = void 0;
  var lists = {
    dqReasons: {
      elements: [{
        label: 'List Item',
        type: 'text',
        path: 'currentItem'
      }],
      listType: 'string'
    },
    labels: {
      elements: [{
        label: 'Label Text',
        type: 'text',
        path: 'text'
      }, {
        type: 'color',
        path: 'color'
      }],
      listType: 'object'
    },
    tags: {
      elements: [{
        label: 'Tag Text',
        type: 'text',
        path: 'currentItem'
      }],
      listType: 'string'
    }
  };
  _exports.lists = lists;
});