define("granite/templates/account/benefits/plans/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z885/hBs",
    "block": "{\"symbols\":[\"plans\",\"label\",\"plan\"],\"statements\":[[4,\"if\",[[23,0,[\"groupedPlans\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"plan-groups\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[23,0,[\"groupedPlans\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"section\",true],[10,\"class\",\"plan-groups__group\"],[8],[0,\"\\n        \"],[7,\"h2\",true],[8],[0,\"\\n          \"],[1,[23,2,[]],false],[0,\"\\n          \"],[7,\"small\",true],[10,\"class\",\"right floated\"],[8],[0,\"\\n            \"],[1,[23,1,[\"length\"]],false],[0,\" \"],[1,[28,\"inflect-text\",[\"Plan\",[23,1,[\"length\"]]],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"ui three stackable cards\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"            \"],[5,\"benefits/plan\",[],[[\"@plan\"],[[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui relaxed divided list\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"ui center aligned header\"],[8],[0,\"Nothing to see here.\"],[9],[0,\"\\n    \"],[7,\"h5\",true],[10,\"class\",\"center aligned text header\"],[8],[0,\"\\n      There are no benefits set up. To offer benefit administration through Granite,\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"account.benefits.plans.integrations\"]],{\"statements\":[[0,\"add a carrier\"]],\"parameters\":[]}],[0,\" or \"],[5,\"link-to\",[],[[\"@route\"],[\"account.benefits.plans.add\"]],{\"statements\":[[0,\"manually add plans\"]],\"parameters\":[]}],[0,\".\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/benefits/plans/index.hbs"
    }
  });

  _exports.default = _default;
});