define("granite/templates/account/job-description/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fIvyjgki",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui very padded raised segment animated fadeInUp\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui top attached green label\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"category\"]]],null,{\"statements\":[[0,\"      Category: \"],[1,[23,0,[\"model\",\"category\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"right floated detail\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"department\",\"name\"]]],null,{\"statements\":[[0,\"        Department: \"],[1,[23,0,[\"model\",\"department\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"assets\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"pull-left light text\"],[8],[0,\"\\n        Usually uses:\\n        \"],[1,[28,\"join-array\",[[28,\"map-by\",[\"name\",[23,0,[\"model\",\"assets\"]]],null],\", \"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"p\",true],[10,\"class\",\"pull-right light text\"],[8],[0,\"Created on \"],[1,[28,\"time\",[[23,0,[\"model\",\"created\"]]],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"h2\",true],[10,\"class\",\"ui clearfix header\"],[8],[0,\"\\n    \"],[1,[23,0,[\"model\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"p\",true],[8],[1,[23,0,[\"model\",\"description\"]],true],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/job-description/index.hbs"
    }
  });

  _exports.default = _default;
});