define("granite/templates/shared", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zAl+2Ps0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui text container\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"ui center aligned header shared__resource-header\"],[8],[0,\"This resource was shared with you\"],[9],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"center aligned small text\"],[8],[0,\"Powered by Granite HR\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/shared.hbs"
    }
  });

  _exports.default = _default;
});