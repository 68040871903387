define("granite/templates/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YgUsyzj6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"about__backplane\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"about-backplane__content\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"About Our Team\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Granite is a product created by \"],[7,\"strong\",true],[8],[0,\"Associated Employers\"],[9],[0,\", a small team of thinkers based in Billings, Montana. Our team is laser-focused on creating great experiences for our members and companies that need guidance with Human Resources. We hope you enjoy Granite HR, and look forward to hearing your feedback so we can improve Granite for you.\"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[7,\"a\",true],[10,\"href\",\"https://associatedemployers.org\"],[10,\"rel\",\"noopener noreferrer\"],[10,\"target\",\"_blank\"],[8],[0,\"Learn more about Associated Employers...\"],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/about.hbs"
    }
  });

  _exports.default = _default;
});