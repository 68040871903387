define("granite/adapters/application", ["exports", "ember-data", "ember-inflector", "ember-ajax/mixins/ajax-support"], function (_exports, _emberData, _emberInflector, _ajaxSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_ajaxSupport.default, {
    namespace: '/api/v1',
    pathForType: function pathForType(type) {
      return (0, _emberInflector.pluralize)(type);
    }
  });

  _exports.default = _default;
});