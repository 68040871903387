define("granite/helpers/add-one", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addOne = addOne;
  _exports.default = void 0;

  function addOne(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        num = _ref2[0];

    return parseFloat(num) + 1;
  }

  var _default = Ember.Helper.helper(addOne);

  _exports.default = _default;
});