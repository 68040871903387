define("granite/models/action-item", ["exports", "ember-data/model", "ember-data", "granite/utils/resolve-for-type-key", "ember-cp-validations"], function (_exports, _model, _emberData, _resolveForTypeKey, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  var Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2
    })]
  });

  var _default = _model.default.extend(Validations, {
    title: attr('string'),
    description: attr('string'),
    priority: attr('number', {
      defaultValue: 1
    }),
    // Relational
    participants: hasMany('employee', {
      async: true,
      inverse: null
    }),
    subscribers: hasMany('employee', {
      async: true,
      inverse: null
    }),
    comments: hasMany('comment', {
      async: true,
      inverse: null
    }),
    prerequisites: hasMany('action-item', {
      async: true,
      inverse: null
    }),
    // attachments:   hasMany('document', { async: true }),
    checklist: hasMany('checklist-item', {
      inverse: null
    }),
    company: belongsTo('company'),
    owner: belongsTo('employee'),
    target: (0, _resolveForTypeKey.default)('target'),
    targetId: attr('string-or-null'),
    targetType: attr('string-or-null'),
    // Comments, non-inverse
    completedOn: attr('date'),
    cancelledOn: attr('date'),
    dueOn: attr('date'),
    delayedUntil: attr('date'),
    remindOn: attr('date'),
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    slug: Ember.computed('title', function () {
      var title = this.title;
      return title ? title.replace(/-/g, '-!').replace(/\s/g, '-') : title;
    }),
    incompleteTodos: Ember.computed.filter('checklist', function (todo) {
      return !todo.get('completedOn');
    }),
    completeTodos: Ember.computed.filter('checklist', function (todo) {
      return todo.get('completedOn');
    }),
    percentComplete: Ember.computed('checklist.[]', 'completeTodos.[]', function () {
      var total = this.get('completeTodos.length') || 0,
          completed = this.get('checklist.length') || 0;
      return total / completed * 100;
    })
  });

  _exports.default = _default;
});