define("granite/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "98tpS2Bq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui hero cover grey overlay hero__500\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"hero__content\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"ui header hero__title\"],[8],[0,\"\\n      \"],[4,\"if\",[[23,0,[\"fromError\",\"status\"]]],null,{\"statements\":[[1,[23,0,[\"fromError\",\"status\"]],false]],\"parameters\":[]},null],[0,\" Uh oh.\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"hero__subcontent\"],[8],[0,\"\\n      \"],[4,\"if\",[[23,0,[\"clientError\"]]],null,{\"statements\":[[0,\"You did something bad.\"]],\"parameters\":[]},{\"statements\":[[0,\"Something is broken.\"]],\"parameters\":[]}],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fromError\",\"responseText\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"\\n          \"],[7,\"code\",true],[8],[1,[23,0,[\"fromError\",\"responseText\"]],false],[9],[0,\" That's all we know.\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"fromError\",\"message\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"\\n          \"],[7,\"code\",true],[8],[1,[23,0,[\"fromError\",\"message\"]],false],[9],[0,\" That's all we know.\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"text-center text-sm hero__subcontent\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"Back to index\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/error.hbs"
    }
  });

  _exports.default = _default;
});