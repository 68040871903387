define("granite/templates/account/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MSbz5CzZ",
    "block": "{\"symbols\":[\"group\",\"category\",\"result\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix search__container\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"ui header search__descriptor\"],[8],[0,\"\\n    Search results for \\\"\"],[1,[23,0,[\"q\"]],false],[0,\"\\\"...\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n\"],[4,\"each\",[[28,\"-each-in\",[[23,0,[\"model\",\"results\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"h2\",true],[10,\"class\",\"search__result-category\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui divided link items\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"results\"]]],null,{\"statements\":[[0,\"        \"],[5,\"search/result-item\",[],[[\"@model\",\"@resultItem\",\"@modelName\",\"@title\",\"@picture\",\"@description\"],[[23,3,[\"_source\"]],[23,3,[]],[23,3,[\"_type\"]],[23,3,[\"title\"]],[23,3,[\"picture\"]],[23,3,[\"description\"]]]]],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"    \"],[7,\"h2\",true],[10,\"class\",\"center aligned text search__no-results\"],[8],[0,\"No results found.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/search.hbs"
    }
  });

  _exports.default = _default;
});