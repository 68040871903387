define("granite/templates/catchall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NYZ1HZCN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui hero cover grey overlay hero__404\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"hero__content\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"ui header hero__title\"],[8],[0,\"\\n      Uh oh. We can't find that page.\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"hero__subcontent\"],[8],[0,\"\\n      The URL you navigated to is missing. This can either be because you typed the URL wrong or we've moved that page.  Sorry about that.\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"text-center text-sm hero__subcontent\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"Back to index\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/catchall.hbs"
    }
  });

  _exports.default = _default;
});