define("granite/templates/account/employee/index/edit/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1R6JO/fX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"externalLinkService\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"automatic-change-sync\"],[8],[0,\"\\n      Automatic Change Syncing\"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"small\",true],[10,\"class\",\"light text\"],[8],[0,\"\\n        Automatic change syncing will automatically sync changes from the record's origin.\"],[7,\"br\",true],[8],[9],[0,\"\\n        If a record has been modified on Granite that conflicts with a change from the origin, a sync conflict will be generated.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    Off\\n    \"],[5,\"ui-checkbox\",[],[[\"@class\",\"@label\",\"@checked\",\"@onChange\"],[\"toggle\",\"On\",[23,0,[\"model\",\"externalLinkAutomaticSync\"]],[28,\"set\",[[23,0,[\"model\"]],\"externalLinkAutomaticSync\",[28,\"-set-placeholder\",null,null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"h3\",true],[10,\"class\",\"ui center aligned text\"],[8],[0,\"No Settings Available.\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/edit/settings.hbs"
    }
  });

  _exports.default = _default;
});