define("granite/templates/setup/email-sent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "whTlWx4H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"striped-background\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui raised very padded text container segment congrats\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui icon message\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"green massive thumbs up icon\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"Congratulations, you're all signed up!\"],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"Check your inbox for a link to set your password\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/setup/email-sent.hbs"
    }
  });

  _exports.default = _default;
});