define("granite/helpers/relative-greeting-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.relativeGreetingTime = relativeGreetingTime;
  _exports.default = void 0;

  function relativeGreetingTime() {
    var hour = new Date().getHours();
    return hour >= 0 && hour < 12 ? 'morning' : hour >= 12 && hour < 17 ? 'afternoon' : 'evening';
  }

  var _default = Ember.Helper.helper(relativeGreetingTime);

  _exports.default = _default;
});