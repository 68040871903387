define("granite/templates/account/employee/complete-offboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uf1Ojsrt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised very padded text segment container animation__complete-pop\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"title\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"thumbs up icon\"],[8],[9],[0,\" All Set! \"],[1,[23,0,[\"model\",\"firstName\"]],false],[0,\" has been offboarded.\\n      \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n      They will be removed from the invoice that reflects the NEXT billing cycle.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/complete-offboarding.hbs"
    }
  });

  _exports.default = _default;
});