define("granite/templates/account/recruiting/job-descriptions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A/rhR+E8",
    "block": "{\"symbols\":[],\"statements\":[[5,\"stateful-intro-js\",[],[[\"@user\",\"@steps\",\"@start-if\",\"@hintKey\"],[[23,0,[\"auth\",\"user\"]],[23,0,[\"intros\"]],true,\"job-description-index\"]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix animated fadeInUp\"],[8],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui header left floated\"],[8],[0,\"\\n      Job Descriptions\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"right aligned text floated menu__actions\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[10,\"class\",\"ui inline header\"],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\"],[\"account.recruiting.job-descriptions.new\"]],{\"statements\":[[0,\"\\n          \"],[7,\"i\",true],[10,\"id\",\"add-job-description\"],[10,\"class\",\"plus icon\"],[8],[9],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[5,\"display/resource-list\",[[12,\"class\",\"ui divided link items\"]],[[\"@model\",\"@itemComponent\",\"@linkTo\",\"@linkClass\"],[[23,0,[\"model\"]],\"list-item/job-item\",\"account.job-description\",\"item\"]]],[0,\"\\n\\n  \"],[5,\"elements/x-pagination\",[],[[\"@page\",\"@model\",\"@limit\",\"@onChange\"],[[23,0,[\"page\"]],[23,0,[\"model\"]],[23,0,[\"limit\"]],[28,\"set\",[[23,0,[]],\"page\",[28,\"-set-placeholder\",null,null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/recruiting/job-descriptions/index.hbs"
    }
  });

  _exports.default = _default;
});