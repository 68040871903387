define("granite/templates/account/benefits/plans/contributions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q3Jyqz8N",
    "block": "{\"symbols\":[],\"statements\":[[5,\"benefits/contributions-table\",[],[[\"@plan\",\"@cancel\",\"@save\"],[[23,0,[\"model\"]],[23,0,[\"cancel\"]],[23,0,[\"save\"]]]]],[0,\"\\n \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/benefits/plans/contributions/edit.hbs"
    }
  });

  _exports.default = _default;
});