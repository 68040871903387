define("granite/services/clock", ["exports", "moment", "granite/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    refreshResolution: 1000,
    t: Ember.computed.alias('time'),
    _tick: Ember.on('init', function () {
      var m = (0, _moment.default)();
      this.setProperties({
        second: m.get('second'),
        minute: m.get('minute'),
        hour: m.get('hour'),
        time: m.toDate()
      });

      if (_environment.default.environment !== 'test') {
        Ember.run.later(this, this._tick, this.refreshResolution);
      }
    })
  });
  /*
    Ember.Controller.extend({
      clock: Ember.inject.service(),
      initializeClock: Ember.on('init', function () {
        this.get('clock');
      }),
      tick: Ember.observer('clock.t', function () {
        // clock update
      })
    });
  */


  _exports.default = _default;
});