define("granite/templates/account/benefits/plans/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yn/rW9BI",
    "block": "{\"symbols\":[],\"statements\":[[5,\"benefits/plan-form\",[],[[\"@plan\",\"@edit\",\"@save\",\"@cancel\"],[[23,0,[\"model\"]],true,[23,0,[\"save\"]],[23,0,[\"cancel\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/benefits/plans/edit.hbs"
    }
  });

  _exports.default = _default;
});