define("granite/models/validations/employee", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberCpValidations.buildValidations)({
    firstName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2
    })],
    lastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2
    })],
    // emergencyContactFirstName: [
    //   validator('presence', true)
    // ],
    //
    // emergencyContactLastName: [
    //   validator('presence', true)
    // ],
    phone: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'phone'
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    privateEmail: [(0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowBlank: true
    })],
    employeeNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.{externalLinkAutomaticSync,company.linkToSlate}', function () {
        return !(this.model.externalLinkAutomaticSync && this.model.get('company.linkedToSlate')); // eslint-disable-next-line ember/no-volatile-computed-properties
      }).volatile() // Disable caching and force recompute on every call

    })],
    addressLine1: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2
    })],
    addressCity: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2
    })],
    addressState: [(0, _emberCpValidations.validator)('presence', true)],
    addressZip: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      is: 5
    })],
    ssn: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      is: 9
    })],
    jobTitle: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2
    })],
    hireDate: [(0, _emberCpValidations.validator)('presence', true)]
  });

  _exports.default = _default;
});