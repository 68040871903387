define("granite/utils/hex-to-rgb", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hexToRgb;

  function hexToRgb(hex) {
    var _map = [1, 3, 5].map(function (d) {
      return parseInt(hex.substring(d, d + 2), 16);
    }),
        _map2 = (0, _slicedToArray2.default)(_map, 3),
        r = _map2[0],
        g = _map2[1],
        b = _map2[2];

    return {
      r: r,
      g: g,
      b: b
    };
  }
});