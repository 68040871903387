define("granite/templates/account/anatomy/company-users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bIas7+ZS",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"ui header left floated\"],[8],[0,\"\\n    Users\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"right aligned text floated menu__actions\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"ui right floated header\"],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"right floated\"]],[[\"@route\"],[\"account.anatomy.company-users.new\"]],{\"statements\":[[0,\"\\n        \"],[5,\"ember-tooltip\",[],[[\"@side\",\"@popperContainer\",\"@text\"],[\"left\",\"body\",\"Add a user\"]]],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"plus icon\"],[8],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divided items\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[5,\"list-item/user-item\",[],[[\"@user\",\"@allUsers\",\"@onRefresh\"],[[23,1,[]],[23,0,[\"allUsers\"]],[28,\"route-action\",[\"refreshModel\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"h2\",true],[10,\"class\",\"ui center aligned header\"],[8],[0,\"No users available.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[5,\"elements/x-pagination\",[],[[\"@page\",\"@model\",\"@limit\",\"@onChange\"],[[23,0,[\"page\"]],[23,0,[\"model\"]],[23,0,[\"limit\"]],[28,\"set\",[[23,0,[]],\"page\",[28,\"-set-placeholder\",null,null]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/anatomy/company-users/index.hbs"
    }
  });

  _exports.default = _default;
});