define("granite/config/statics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cannedTypes = _exports.compensationUnits = _exports.terminationReasons = _exports.planTypes = _exports.statesIncomeTaxExempt = _exports.filingStatuses = _exports.bankAccountTypes = _exports.recordDisplayPropertyMap = _exports.modelPageMap = _exports.closeMessageMap = _exports.formTypes = _exports.ethnicGroups = _exports.eeoJobCategories = _exports.jobTypes = _exports.jobCategories = _exports.states = _exports.suffixes = _exports.issueTypes = _exports.gender = _exports.accountNavigationItems = void 0;
  var suffixes = ['Sr.', 'Jr.', 'I', 'II', 'III', 'IV'];
  _exports.suffixes = suffixes;
  var planTypes = {
    M: {
      icon: 'stethoscope',
      label: 'Health'
    },
    D: {
      icon: 'clipboard outline',
      label: 'Dental'
    },
    V: {
      icon: 'eye',
      label: 'Vision'
    },
    L: {
      icon: 'life ring outline',
      label: 'Life'
    },
    O: {
      icon: 'plus square',
      label: 'Other'
    }
  };
  _exports.planTypes = planTypes;
  var states = [{
    value: 'AL',
    label: 'Alabama'
  }, {
    value: 'AK',
    label: 'Alaska'
  }, {
    value: 'AZ',
    label: 'Arizona'
  }, {
    value: 'AR',
    label: 'Arkansas'
  }, {
    value: 'CA',
    label: 'California'
  }, {
    value: 'CO',
    label: 'Colorado'
  }, {
    value: 'CT',
    label: 'Connecticut'
  }, {
    value: 'DE',
    label: 'Delaware'
  }, {
    value: 'DC',
    label: 'District of Columbia'
  }, {
    value: 'FL',
    label: 'Florida'
  }, {
    value: 'GA',
    label: 'Georgia'
  }, {
    value: 'HI',
    label: 'Hawaii'
  }, {
    value: 'ID',
    label: 'Idaho'
  }, {
    value: 'IL',
    label: 'Illinois'
  }, {
    value: 'IN',
    label: 'Indiana'
  }, {
    value: 'IA',
    label: 'Iowa'
  }, {
    value: 'KS',
    label: 'Kansas'
  }, {
    value: 'KY',
    label: 'Kentucky'
  }, {
    value: 'LA',
    label: 'Louisiana'
  }, {
    value: 'ME',
    label: 'Maine'
  }, {
    value: 'MD',
    label: 'Maryland'
  }, {
    value: 'MA',
    label: 'Massachusetts'
  }, {
    value: 'MI',
    label: 'Michigan'
  }, {
    value: 'MN',
    label: 'Minnesota'
  }, {
    value: 'MS',
    label: 'Mississippi'
  }, {
    value: 'MO',
    label: 'Missouri'
  }, {
    value: 'MT',
    label: 'Montana'
  }, {
    value: 'NE',
    label: 'Nebraska'
  }, {
    value: 'NV',
    label: 'Nevada'
  }, {
    value: 'NH',
    label: 'New Hampshire'
  }, {
    value: 'NJ',
    label: 'New Jersey'
  }, {
    value: 'NM',
    label: 'New Mexico'
  }, {
    value: 'NY',
    label: 'New York'
  }, {
    value: 'NC',
    label: 'North Carolina'
  }, {
    value: 'ND',
    label: 'North Dakota'
  }, {
    value: 'OH',
    label: 'Ohio'
  }, {
    value: 'OK',
    label: 'Oklahoma'
  }, {
    value: 'OR',
    label: 'Oregon'
  }, {
    value: 'PA',
    label: 'Pennsylvania'
  }, {
    value: 'RI',
    label: 'Rhode Island'
  }, {
    value: 'SC',
    label: 'South Carolina'
  }, {
    value: 'SD',
    label: 'South Dakota'
  }, {
    value: 'TN',
    label: 'Tennessee'
  }, {
    value: 'TX',
    label: 'Texas'
  }, {
    value: 'UT',
    label: 'Utah'
  }, {
    value: 'VT',
    label: 'Vermont'
  }, {
    value: 'VA',
    label: 'Virginia'
  }, {
    value: 'WA',
    label: 'Washington'
  }, {
    value: 'WV',
    label: 'West Virginia'
  }, {
    value: 'WI',
    label: 'Wisconsin'
  }, {
    value: 'WY',
    label: 'Wyoming'
  }];
  _exports.states = states;
  var jobCategories = ['Accounting/Finance', 'Healthcare', 'Non-Profit/Volunteering', 'Administrative', 'Computer/Internet', 'Pharmaceutical/Bio-tech', 'Arts/Entertainment/Publishing', 'Hospitality/Travel', 'Real Estate', 'Banking/Loans', 'Human Resources', 'Restaurant/Food Service', 'Construction/Facilities', 'Insurance', 'Retail', 'Customer Service', 'Law Enforcement/Security', 'Sales', 'Education/Training', 'Legal', 'Telecommunications', 'Engineering/Architecture', 'Manufacturing/Mechanical', 'Transportation/Logistics', 'Government/Military', 'Marketing/Advertising/PR', 'Upper Management/Consulting'];
  _exports.jobCategories = jobCategories;
  var jobTypes = ['Full Time', 'Part Time', 'Contract', 'Internship', 'Temporary', 'Seasonal', 'Commission', null];
  _exports.jobTypes = jobTypes;
  var eeoJobCategories = ['Executive/Senior Level Officials and Managers', 'First/Mid Level Officials and Managers', 'Professionals', 'Technicians', 'Sales Workers', 'Administrative Support Workers', 'Craft Workers', 'Operatives', 'Laborers and Helpers', 'Service Workers'];
  _exports.eeoJobCategories = eeoJobCategories;
  var gender = ['Male', 'Female', 'Other'];
  _exports.gender = gender;
  var ethnicGroups = [{
    value: 'W',
    label: 'White/Caucasian'
  }, {
    value: 'B',
    label: 'Black/African American'
  }, {
    value: 'I',
    label: 'American Indian/Alaskan Native'
  }, {
    value: 'H',
    label: 'Hispanic/Latino'
  }, {
    value: 'A',
    label: 'Asian'
  }, {
    value: 'P',
    label: 'Native Hawaiian or Other Pacific Islander'
  }, {
    value: 'T',
    label: 'More than one of the above'
  }];
  _exports.ethnicGroups = ethnicGroups;
  var formTypes = [{
    display: 'Freeform Input',
    icon: 'text cursor',
    use: 'textarea'
  }, {
    display: 'Dropdown Selection',
    icon: 'caret down',
    use: 'select'
  }, {
    display: 'Radio Selection',
    icon: 'radio',
    use: 'radio'
  }, {
    display: 'Checkbox',
    icon: 'check square',
    use: 'checkbox'
  }, {
    display: 'Date',
    icon: 'calendar',
    use: 'date'
  }, {
    display: 'Toggle',
    icon: 'toggle off',
    use: 'toggle'
  }];
  _exports.formTypes = formTypes;
  var bankAccountTypes = ['Checking', 'Savings'];
  _exports.bankAccountTypes = bankAccountTypes;
  var issueTypes = ['Performance', 'Behavior', 'Performance & Behavior'];
  _exports.issueTypes = issueTypes;
  var closeMessageMap = {
    prefix: 'Based on your settings',
    default: 'Are you sure you want to close this job opening?',
    sendCloseNotice: 'an email will be sent to those who were not hired',
    allocateTalentPool: 'non-disqualified candidates will be added to your talent pool'
  };
  _exports.closeMessageMap = closeMessageMap;
  var modelPageMap = {
    departments: {
      path: 'account.anatomy.departments',
      all: true
    },
    locations: {
      path: 'account.anatomy.locations',
      all: true
    },
    employees: {
      path: 'account.employee',
      all: false
    }
  };
  _exports.modelPageMap = modelPageMap;
  var recordDisplayPropertyMap = {
    departments: ['name'],
    locations: ['name'],
    employees: ['name.first', 'name.last']
  };
  _exports.recordDisplayPropertyMap = recordDisplayPropertyMap;
  var accountNavigationItems = [{
    icon: 'tachometer alternate',
    title: 'Dashboard',
    link: 'index'
  }, {
    icon: 'check',
    title: 'Projects',
    link: 'action-items'
  }, {
    icon: 'heartbeat',
    title: 'Benefits',
    link: 'benefits',
    beta: true
  }, {
    icon: 'users',
    title: 'Employees',
    link: 'employees'
  }, {
    icon: 'graduation cap',
    title: 'Educate',
    link: 'education',
    beta: true
  }, {
    icon: 'user add',
    title: 'Recruiting',
    link: 'recruiting'
  }, {
    icon: 'file',
    title: 'Documents',
    link: 'documents'
  }, {
    icon: 'mobile',
    title: 'Company Assets',
    link: 'assets'
  }, {
    icon: 'sitemap',
    title: 'Company Anatomy',
    link: 'anatomy'
  }, {
    icon: 'handshake outline',
    title: 'HR Resources',
    link: 'hr'
  }, {
    icon: 'life ring outline',
    title: 'Help',
    link: 'help'
  }];
  _exports.accountNavigationItems = accountNavigationItems;
  var maritalStatuses = ['Single', 'Married'];
  var statesIncomeTaxExempt = ['AK', 'FL', 'NV', 'NH', 'SD', 'TN', 'TX', 'WA', 'WY'];
  _exports.statesIncomeTaxExempt = statesIncomeTaxExempt;
  var filingStatuses = ['Exempt'].concat(maritalStatuses, ['Head of Household']);
  _exports.filingStatuses = filingStatuses;
  var terminationReasons = [{
    value: '01',
    label: 'End of Employment'
  }, {
    value: '02',
    label: 'Retirement'
  }, {
    value: '04',
    label: 'Death of Employee'
  }, {
    value: '05',
    label: 'Loss of Dependent Child Status'
  }, {
    value: '06',
    label: 'Reduction in Hours of Employment'
  }, {
    value: '08',
    label: 'Divorce or Legal Separation'
  }, {
    value: '13',
    label: 'Voluntary Drop'
  }, {
    value: '14',
    label: 'Terminated for Gross Misconduct'
  }];
  _exports.terminationReasons = terminationReasons;
  var compensationUnits = ['hour', 'day', 'week', 'month', 'year', 'TBD'];
  _exports.compensationUnits = compensationUnits;
  var cannedTypes = [{
    title: 'I-9 Employment Eligibility Verification',
    icon: 'id card',
    shortTitle: 'I-9',
    property: 'autoI9Collection',
    type: 'I9'
  }, {
    title: 'W-4 Employee\'s Witholding Certificate',
    icon: 'dollar',
    shortTitle: 'W-4',
    property: 'autoW4Collection',
    type: 'W4'
  }, {
    title: 'State W-4 Employee\'s Witholding Certificate',
    icon: 'dollar',
    shortTitle: 'State W-4',
    property: 'autoStateW4Collection',
    type: 'State W4'
  }];
  _exports.cannedTypes = cannedTypes;
});