define("granite/templates/account/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wiXoE7I6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui text container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui pointing menu\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[7,\"i\",true],[10,\"class\",\"settings icon\"],[8],[9],[9],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.settings.index\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"setting icon\"],[8],[9],[0,\"General\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.settings.billing\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"dollar icon\"],[8],[9],[0,\"Billing\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.settings.integrations\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"exchange icon\"],[8],[9],[0,\"Integrations\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.settings.templates\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"file text outline icon\"],[8],[9],[0,\"Templates\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.settings.user\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"user icon\"],[8],[9],[0,\"User\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui padded raised segment\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/settings.hbs"
    }
  });

  _exports.default = _default;
});