define("granite/templates/account/employees/changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "30x9ZiuJ",
    "block": "{\"symbols\":[\"context\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix\"],[8],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui left floated header\"],[8],[0,\"\\n      Changes\\n    \"],[9],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"ui right floated header\"],[8],[0,\"\\n      \"],[1,[23,0,[\"model\",\"length\"]],false],[0,\" in queue\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[5,\"display/resource-list\",[[12,\"class\",\"ui divided items\"]],[[\"@model\",\"@itemComponent\"],[[23,0,[\"model\"]],\"list-item/change-item\"]],{\"statements\":[[0,\"\\n      \"],[6,[23,1,[\"listItem\"]],[],[[\"@onApprove\",\"@onReject\"],[[23,0,[\"approveChange\"]],[23,0,[\"rejectChange\"]]]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h2\",true],[10,\"class\",\"center aligned text\"],[8],[1,[28,\"emoji-icon\",[\"tada\"],null],false],[0,\" \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"No changes left to review!\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employees/changes.hbs"
    }
  });

  _exports.default = _default;
});