define("granite/templates/account/anatomy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eqyUkZOr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui pointing menu\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.anatomy.index\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"list icon\"],[8],[9],[0,\"Summary\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.anatomy.departments.index\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"cubes icon\"],[8],[9],[0,\"Departments\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.anatomy.locations.index\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"building icon\"],[8],[9],[0,\"Locations\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.anatomy.company-users.index\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"users icon\"],[8],[9],[0,\"Users\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui padded raised text segment clearfix\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/anatomy.hbs"
    }
  });

  _exports.default = _default;
});