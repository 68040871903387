define("granite/templates/account/import/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LzqJSrwT",
    "block": "{\"symbols\":[\"execute\",\"mapper\",\"service\"],\"statements\":[[7,\"form\",false],[12,\"class\",\"ui form\"],[3,\"on\",[\"submit\",[28,\"prevent-default\",[[23,0,[\"discover\"]]],null]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"import-services\"],[8],[0,\"Select an external source to import from\"],[9],[0,\"\\n    \"],[5,\"ui-dropdown\",[],[[\"@class\",\"@id\",\"@selected\",\"@onChange\"],[\"fluid selection\",\"import-services\",[23,0,[\"service\"]],[28,\"set\",[[23,0,[]],\"service\",[28,\"-set-placeholder\",null,null]],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"default text\"],[8],[0,\"Select an external source\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\",\"linkedServices\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"item\"],[11,\"data-value\",[28,\"map-value\",[[23,2,[]],[23,3,[]]],null]],[8],[0,\"\\n            \"],[1,[23,3,[]],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"],[9],[0,\"\\n    \"]],\"parameters\":[1,2]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[5,\"form/action-button\",[[12,\"class\",\"ui massive fluid green button\"],[12,\"type\",\"submit\"]],[[\"@disabled\",\"@loading\"],[[28,\"not\",[[23,0,[\"service\"]]],null],[23,0,[\"data\",\"statuses\",\"working\",\"isLoading\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"cloud download icon\"],[8],[9],[0,\" Discover Records\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/import/index.hbs"
    }
  });

  _exports.default = _default;
});