define("granite/templates/account/job-opening/setup-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aWvf/18J",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised very padded text segment container animation__complete-pop\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"title\"],[8],[7,\"i\",true],[10,\"class\",\"thumbs up icon\"],[8],[9],[0,\" Great work! Your recruiting campaign is all set up.\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"manualApplicantSources\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h2\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n        Don't forget to set up your manual application sources!\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/job-opening/setup-complete.hbs"
    }
  });

  _exports.default = _default;
});