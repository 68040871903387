define("granite/utils/humanize-key-name", ["exports", "granite/utils/title-case"], function (_exports, _titleCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = humanizeKeyName;
  var ES = Ember.String;
  var canned = {
    _id: 'Id',
    __v: 'Version'
  };

  function humanizeKeyName(key) {
    if (!key) {
      return key;
    }

    if (canned[key]) {
      return canned[key];
    }

    var transformedKey = key.split('.').map(function (p) {
      return ES.decamelize(p).replace(/([a-z])([0-9])/g, '$1 $2');
    }).join(' ');
    return (0, _titleCase.default)([transformedKey.replace(/_/g, ' ')]);
  }
});