define("granite/templates/account/recruiting/index/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W9M4O8m2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix\"],[8],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui header left floated\"],[8],[0,\"\\n      New Position\\n    \"],[9],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"right floated\"],[8],[5,\"link-to\",[],[[\"@route\"],[\"account.recruiting.job-descriptions.new\"]],{\"statements\":[[0,\"Add a job description...\"]],\"parameters\":[]}],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[5,\"quick-form\",[],[[\"@form\",\"@model\",\"@controller\",\"@working\",\"@submitClass\",\"@onsubmit\",\"@disabled\"],[[23,0,[\"form\"]],[23,0,[\"model\"]],[23,0,[]],[23,0,[\"data\",\"statuses\",\"savingCampaign\",\"isLoading\"]],\"huge fluid green\",[23,0,[\"save\"]],[28,\"or\",[[28,\"and\",[[28,\"not\",[[23,0,[\"model\",\"name\"]]],null],[28,\"not\",[[23,0,[\"model\",\"defaultName\"]]],null]],null],[28,\"not\",[[23,0,[\"model\",\"job\"]]],null]],null]]],{\"statements\":[[0,\"\\n    Save and Continue\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/recruiting/index/new.hbs"
    }
  });

  _exports.default = _default;
});