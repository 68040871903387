define("granite/templates/account/benefits/open-enrollment/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tTkeOgAS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[12,\"class\",\"ui form\"],[3,\"on\",[\"submit\",[28,\"prevent-default\",[[28,\"fn\",[[23,0,[\"data\",\"saveRecord\"]],[23,0,[\"model\"]],\"working\",[23,0,[\"afterSaveOptions\"]]],null]],null]]],[8],[0,\"\\n  \"],[5,\"quick-form\",[[12,\"class\",\"ui two fields\"]],[[\"@form\",\"@model\",\"@controller\",\"@inputClass\",\"@tagName\"],[[23,0,[\"enrollmentForm\"]],[23,0,[\"model\"]],[23,0,[]],\"field\",\"div\"]]],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[5,\"form/action-button\",[[12,\"class\",\"ui big fluid green button\"],[12,\"type\",\"submit\"]],[[\"@loading\",\"@disabled\"],[[23,0,[\"data\",\"statuses\",\"working\",\"isLoading\"]],[28,\"not\",[[28,\"and\",[[23,0,[\"model\",\"end\"]],[23,0,[\"model\",\"start\"]]],null]],null]]],{\"statements\":[[0,\"Update\"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\"],[\"account.benefits.open-enrollment\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui large fluid orange button\"],[8],[0,\"Cancel\"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/benefits/open-enrollment/edit.hbs"
    }
  });

  _exports.default = _default;
});