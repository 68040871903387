define("granite/templates/account/job-opening/campaign/talent-pool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wvXJP2xb",
    "block": "{\"symbols\":[\"applicant\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui very padded raised segment animated fadeInUp clearfix\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"ui clearfix header\"],[8],[0,\"\\n    Talent Pool\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divided list items\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[5,\"list-item/talent-pool-applicant\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"hgroup\",true],[10,\"class\",\"center aligned text\"],[8],[0,\"\\n        \"],[7,\"h2\",true],[8],[0,\"No applicants found.\"],[9],[0,\"\\n        \"],[7,\"h4\",true],[10,\"class\",\"light text\"],[8],[0,\"\\n          No applicants were found in your talent pool. If you close a campaign with unrejected applicants,\\n          and \"],[5,\"link-to\",[],[[\"@route\"],[\"account.job-opening.campaign.settings.index\"]],{\"statements\":[[0,\"Add Unrejected Applicants To Talent Pool\"]],\"parameters\":[]}],[0,\" is enabled, applicants will show in future campaigns for this job.\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[5,\"elements/x-pagination\",[],[[\"@page\",\"@pages\",\"@limit\",\"@onChange\"],[[23,0,[\"page\"]],[23,0,[\"pages\"]],[23,0,[\"limit\"]],[28,\"set\",[[23,0,[]],\"page\",[28,\"-set-placeholder\",null,null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/job-opening/campaign/talent-pool.hbs"
    }
  });

  _exports.default = _default;
});