define("granite/templates/account/employees/forms/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RGMJhCeP",
    "block": "{\"symbols\":[\"responseGroup\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui relaxed divided list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[5,\"list-item/form-group-item\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"h2\",true],[8],[0,\"No forms available.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employees/forms/index.hbs"
    }
  });

  _exports.default = _default;
});