define("granite/templates/account/employee/index/enrollments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q5y0FGWi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix animated fadeInUp\"],[8],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui header left floated\"],[8],[0,\"\\n      Enrollments\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divided items\"],[8],[0,\"\\n    \"],[5,\"display/resource-list\",[[12,\"class\",\"ui divided link items\"]],[[\"@model\",\"@itemComponent\",\"@linkTo\",\"@linkClass\",\"@modelName\"],[[23,0,[\"model\"]],\"benefits/enrollments/enrollments-list\",\"account.benefits.enrollments.enrollment.index\",\"item\",\"Enrollment\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"elements/x-pagination\",[],[[\"@page\",\"@model\",\"@limit\",\"@onChange\"],[[23,0,[\"page\"]],[23,0,[\"model\"]],[23,0,[\"limit\"]],[28,\"set\",[[23,0,[]],\"page\",[28,\"-set-placeholder\",null,null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/enrollments.hbs"
    }
  });

  _exports.default = _default;
});