define("granite/mixins/wizard/route", ["exports", "granite/mixins/ajax-status"], function (_exports, _ajaxStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxStatus.default, {
    auth: Ember.inject.service(),

    /*
      The key of properties set on the model (ex. for offboarding 'offboarding')
      This will set offboarding, offboardingStep, and offboardingProgress on the
      current model.
     */
    key: null,

    /*
      The route path of where the wizard is located. If your wizard is at
      "account.employee.setup", this should be the base path
     */
    basePath: null,

    /*
      Where the user will be returned to if no more steps exist.
     */
    returnPath: null,

    /*
      If this property is set, we will set the property here on the model as the
      current user in "auth".
      ex. setUserOn = 'offboarder', every step we will make sure the current
      user is set on model.offboarder
     */
    setUserOn: null,
    afterModel: function afterModel(model) {
      var _this = this;

      this._super.apply(this, arguments);

      var step = model.get("".concat(this.key, "Step")),
          steps = this.steps,
          basePath = this.basePath;

      if (step && step > 1) {
        Ember.run.scheduleOnce('afterRender', function () {
          if (_this.get('controller.currentStepIndex') === 1) {
            var l = Ember.get(steps.objectAt(step), 'link');

            _this.transitionTo("".concat(basePath, ".").concat(l));
          }
        });
      }
    },
    setupController: function setupController(controller) {
      controller.setProperties({
        steps: this.steps,
        basePath: this.basePath
      });

      this._super.apply(this, arguments);
    },
    actions: {
      saveAndContinue: function saveAndContinue() {
        var _this2 = this;

        var controller = this.controller,
            model = controller.model;
        this.ajaxStart();
        var key = this.key,
            morph = {};
        morph[key] = true;
        morph[key + 'Step'] = controller.currentStepIndex;
        morph[key + 'Progress'] = parseInt(controller.progress, 0);

        if (this.setUserOn) {
          morph[this.setUserOn] = this.get('auth.user');
        }

        model.setProperties(morph);
        model.save().then(function () {
          _this2.ajaxSuccess('Successfully saved progress.');

          if (!controller.nextStep) {
            _this2.transitionTo(_this2.returnPath);

            return;
          }

          _this2.transitionTo("".concat(_this2.basePath, ".").concat(controller.get('nextStep.link')));
        }).catch(this.ajaxError.bind(this));
      }
    }
  });

  _exports.default = _default;
});