define("granite/components/lt-foot", ["exports", "ember-light-table/components/lt-foot"], function (_exports, _ltFoot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ltFoot.default;
    }
  });
});