define("granite/components/input/comment-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Textarea @value={{this.value}} />
  
  */
  {
    id: "Q0ZBNiqi",
    block: "{\"symbols\":[],\"statements\":[[5,\"textarea\",[],[[\"@value\"],[[23,0,[\"value\"]]]]],[0,\"\"]],\"hasEval\":false}",
    meta: {
      moduleName: "granite/components/input/comment-box.hbs"
    }
  });

  var debug = Ember.Logger.debug;

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    store: Ember.inject.service(),
    atOptions: {},
    _atOptions: Ember.computed(function () {
      var _this = this;

      return this.store.query('employee', {
        select: 'name'
      }).then(function (employees) {
        var opts = _this.atOptions;
        return [Ember.merge(opts, {
          at: '@',
          data: employees.map(function (employee) {
            return {
              id: employee.get('id'),
              name: employee.get('fullName')
            };
          })
        })];
      });
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this._atOptions.then(function (options) {
        Ember.run.scheduleOnce('afterRender', function () {
          var $textarea = _this2.$('textarea');

          debug('Attaching at.js to textarea:', $textarea[0]);
          options.forEach(function (optionSet) {
            debug('Initializing at.js option set', optionSet);
            $textarea.atwho(optionSet);
          });
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$('textarea').atwho('destroy');
    }
  }));

  _exports.default = _default;
});