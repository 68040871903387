define("granite/templates/account/job-opening/campaign/job-application/event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y5Gk0HyM",
    "block": "{\"symbols\":[\"attendee\"],\"statements\":[[7,\"h3\",true],[8],[1,[28,\"if\",[[23,0,[\"model\",\"title\"]],[23,0,[\"model\",\"title\"]],\"Meeting\"],null],false],[0,\" on \"],[1,[28,\"time\",[[23,0,[\"model\",\"start\"]],\"M/D/YY\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n\"],[7,\"h4\",true],[8],[0,\"Participants\"],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"attendees\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"list-item/participant\",[[23,1,[\"person\"]]],[[\"detail\"],[[23,1,[\"type\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"h5\",true],[10,\"class\",\"ui light header\"],[8],[0,\"\\n    No participants are listed for this event.\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"h4\",true],[8],[0,\"Notes\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/job-opening/campaign/job-application/event.hbs"
    }
  });

  _exports.default = _default;
});