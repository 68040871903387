define("granite/services/messaging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    socket: Ember.inject.service(),
    queueDrainTimeout: 800,
    __readQueue: Ember.A(),
    markMessageRead: function markMessageRead(message) {
      this.__readQueue.push(message._id);

      Ember.run.debounce(this, this.__drainReadQueue, this.queueDrainTimeout);
    },
    __drainReadQueue: function __drainReadQueue() {
      var ids = this.__readQueue.toArray();

      this.socket.emit('message_read', {
        ids: ids
      });

      this.__readQueue.removeObjects(ids);
    },
    subscribe: function subscribe(event, fn, context, key) {
      var listenersKey = "".concat(event, "__listeners");

      if (!this.get(listenersKey)) {
        this.set(listenersKey, {});
      }

      var listenerKey = key || Math.round(Math.random() * 10000);
      this.set("".concat(listenersKey, ".").concat(listenerKey), fn.bind(context));

      if (!this.get("".concat(event, "__subscribed"))) {
        this.__initializeSubscription(event);
      }

      return listenerKey;
    },
    unsubscribe: function unsubscribe(event, key) {
      if (!key) {
        throw new Error('Listener key is required.');
      }

      var listenersKey = "".concat(event, "__listeners");

      if (!this.get(listenersKey)) {
        return;
      }

      this.set("".concat(listenersKey, ".").concat(key), undefined);
    },
    __initializeSubscription: function __initializeSubscription(event) {
      var _this = this;

      this.set("".concat(event, "__subscribed"), true);
      this.socket.on(event, function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var listeners = _this.get("".concat(event, "__listeners")) || {};
        Object.keys(listeners).forEach(function (key) {
          listeners[key](args);
        });
      });
    }
  });

  _exports.default = _default;
});