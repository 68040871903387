define("granite/mixins/controller-abstractions/resource", ["exports", "granite/mixins/controller-abstractions/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_pagination.default, {
    queryParams: ['page', 'limit'],
    limit: 20,
    page: 1
  });

  _exports.default = _default;
});