define("granite/models/validations/job", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3
    })],
    description: [(0, _emberCpValidations.validator)('presence', true)],
    category: [(0, _emberCpValidations.validator)('presence', true)]
  });

  _exports.default = _default;
});