define("granite/templates/account/anatomy/departments/index/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6ZRdXN7/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[12,\"class\",\"ui form container\"],[3,\"on\",[\"submit\",[28,\"prevent-default\",[[28,\"pipe\",[[28,\"fn\",[[23,0,[\"data\",\"saveRecord\"]],[23,0,[\"model\"]],\"working\",[23,0,[\"saveOptions\"]]],null],[28,\"route-action\",[\"refreshModel\"],null]],null]],null]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"department-name\"],[8],[0,\"Name\"],[9],[0,\"\\n    \"],[5,\"input\",[[12,\"placeholder\",\"Department Name\"]],[[\"@type\",\"@value\",\"@id\"],[\"text\",[23,0,[\"model\",\"name\"]],\"department-name\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"department-code\"],[8],[0,\"Code\"],[9],[0,\"\\n    \"],[5,\"input\",[[12,\"placeholder\",\"Department Code\"]],[[\"@type\",\"@value\",\"@id\"],[\"text\",[23,0,[\"model\",\"code\"]],\"department-code\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[5,\"form/action-button\",[[12,\"class\",\"ui huge fluid green button\"],[12,\"type\",\"submit\"]],[[\"@loading\"],[[23,0,[\"data\",\"statuses\",\"working\",\"isLoading\"]]]],{\"statements\":[[0,\"Add Department\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/anatomy/departments/index/new.hbs"
    }
  });

  _exports.default = _default;
});