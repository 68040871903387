define("granite/components/visualizations/organization-tree", ["exports", "d3-selection", "d3-zoom", "d3-hierarchy"], function (_exports, _d3Selection, _d3Zoom, _d3Hierarchy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    id: "BwUq+ehy",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "granite/components/visualizations/organization-tree.hbs"
    }
  });
  /* eslint-disable ember/no-on-calls-in-components */


  var select = 'name email jobTitle supervisor';

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: 'svg',
    ajax: Ember.inject.service(),
    classNames: ['visualization__org-tree', 'visualization__org-tree--full'],
    attributeBindings: ['width', 'height'],
    nodeRadius: 8,
    baseNodeRadius: 11,
    margin: {
      top: 40,
      right: 90,
      bottom: 50,
      left: 90
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._dataUpdate; //eslint-disable-line
    },
    populateChildNodes: function populateChildNodes(node, base) {
      var _this = this;

      var ajax = this.ajax,
          baseNode = this.baseNode;
      Ember.set(node, 'children', Ember.A());
      return ajax.request('/api/v1/employees', {
        data: {
          select: select,
          supervisor: Ember.get(node, '_id'),
          $or: [{
            terminatedOn: {
              $not: {
                $type: 9
              }
            }
          }, {
            terminatedOn: {
              $gte: new Date()
            }
          }]
        }
      }).then(function (res) {
        var children = res.employee;

        if (children && children.length > 0) {
          return Ember.RSVP.map(children, function (child) {
            var _child = baseNode && Ember.get(baseNode, '_id') === Ember.get(child, '_id') ? Ember.Object.create(baseNode) : child;

            if (!base, Ember.get(_child, '_id') === Ember.get(baseNode, '_id')) {
              return;
            }

            return _this.populateChildNodes(_child).then(function (populated) {
              return Ember.get(node, 'children').push(populated);
            });
          });
        } else {
          return Ember.RSVP.Promise.resolve();
        }
      }).then(function () {
        Ember.set(node, 'children', Ember.get(node, 'children').toArray());
        return node;
      });
    },
    _simulation: Ember.computed('baseNode._id', function () {
      var baseNode = this.baseNode;
      return this.populateChildNodes(Ember.Object.create(baseNode), true);
    }),
    _baseRender: Ember.on('didInsertElement', function () {
      var _this2 = this;

      this.setProperties({
        width: this.$().width(),
        height: this.$().height()
      });
      return Ember.run.next(function () {
        Ember.run.scheduleOnce('afterRender', function () {
          var margin = _this2.margin,
              width = margin.right - margin.left,
              height = margin.top - margin.bottom;

          var zoom = _d3Zoom.default.zoom();

          var svg = _d3Selection.default.select('#' + _this2.elementId).attr('minwidth', width).attr('minheight', height).call(zoom.on('zoom', function () {
            svg.attr('transform', _d3Selection.default.event.transform);
          })).append('g'),
              g = svg.append('g').attr('transform', "translate(".concat(margin.left, ",").concat(margin.top, ")"));

          _this2.setProperties({
            svg: svg,
            g: g
          });

          return _this2._dataUpdate;
        });
      });
    }),
    _dataUpdate: Ember.computed('_simulation', 'nodeRadius', 'baseNodeRadius', function () {
      var _this3 = this;

      var minimumXSpacing = 135;
      return this._simulation.then(function (data) {
        Ember.run.scheduleOnce('afterRender', function () {
          var root = (0, _d3Hierarchy.hierarchy)(data),
              g = _this3.g,
              baseNodeId = _this3.get('baseNode._id'),
              nr = _this3.nodeRadius,
              bnr = _this3.baseNodeRadius,
              width = _this3.width,
              height = _this3.height;

          if (width / root.height > minimumXSpacing) {
            width = minimumXSpacing * root.height;
          }

          var clust = (0, _d3Hierarchy.tree)().size([height, width])(root);
          g.selectAll('.link').remove();
          g.selectAll('.node').remove();
          var link = g.selectAll('.link').data(clust.descendants().slice(1));
          link.exit().remove();
          link.enter().append('path').attr('class', 'link').merge(link).attr('d', function (d) {
            return 'M' + d.y + ',' + d.x + 'C' + (d.parent.y + 100) + ',' + d.x + ' ' + (d.parent.y + 100) + ',' + d.parent.x + ' ' + d.parent.y + ',' + d.parent.x;
          });
          var nodes = g.selectAll('.node').data(clust.descendants());
          nodes.exit().remove();
          var node = nodes.enter().append('g');
          node.merge(nodes).attr('class', function (d) {
            var c = 'node';
            c += d.children ? ' node--internal' : ' node--leaf';

            if (d.data.deprecating) {
              c += ' node--deprecating';
            }

            if (d.data._id === baseNodeId) {
              c += ' node--base';
            }

            return c;
          }).attr('transform', function (d) {
            return 'translate(' + d.y + ',' + d.x + ')';
          }); // The data DOES get passed down to the circles, and the enter() statement
          // will create a circle child for each data entry

          node.append('circle').attr('r', function (e) {
            return e.data._id === baseNodeId ? bnr : nr;
          }).exit().remove();
          node.append('text').attr('x', '1.5em').attr('dy', function (d) {
            return (d.data._id === baseNodeId ? bnr : nr) / 3;
          }).style('text-anchor', 'start').text(function (d) {
            var n = [],
                _ref2 = d.data.name || {},
                first = _ref2.first,
                last = _ref2.last;

            if (first) {
              n.push(first);
            }

            if (last) {
              n.push(last);
            }

            return n.join(' ');
          }).exit().remove();
        });
      });
    })
  }));

  _exports.default = _default;
});