define("granite/templates/account/employees/add/census/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RzprUrlW",
    "block": "{\"symbols\":[],\"statements\":[[5,\"elements/file-uploader\",[],[[\"@url\",\"@autoUpload\",\"@allowedExtensions\",\"@dragText\",\"@onWinning\",\"@onError\"],[\"/api/v1/employee/census/upload\",true,[23,0,[\"supportedExtensions\"]],\"Drag Your Census File Here\",[23,0,[\"successHandler\"]],[23,0,[\"fileUploadError\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employees/add/census/index.hbs"
    }
  });

  _exports.default = _default;
});