define("granite/templates/account/employee/index/future-changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+PGBfNw4",
    "block": "{\"symbols\":[\"change\"],\"statements\":[[4,\"if\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h2\",true],[10,\"class\",\"ui center aligned text text-white justified header\"],[8],[0,\"Future Changes\"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui one stackable cards\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[5,\"list-item/unapplied-change\",[],[[\"@history\",\"@onApplyNow\"],[[23,1,[]],[23,0,[\"notifyNewEffective\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"h2\",true],[10,\"class\",\"center aligned text text-white\"],[8],[0,\"No Changes Available\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/future-changes.hbs"
    }
  });

  _exports.default = _default;
});