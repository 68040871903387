define("granite/config/forms/educate-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forms = void 0;
  var forms = {
    'certification': {
      elements: [{
        label: 'Name',
        type: 'text',
        path: 'name'
      }, {
        label: 'Notes',
        type: 'textarea',
        rows: '3',
        path: 'note'
      }, {
        label: 'Initial Date',
        helpText: 'Specify original date of certification. Useful especially for renewals.',
        type: 'date',
        path: 'initialDate'
      }, {
        label: 'Renews',
        type: 'checkbox',
        path: 'renews'
      }, {
        displayIf: 'renews',
        label: 'Renews Every',
        type: 'number',
        path: 'renewalPeriodAmount',
        parentClass: 'inside-input__container',
        embeddedInput: {
          showLabel: false,
          inputClass: 'inline inside-input',
          removeBaseClass: true,
          type: 'select',
          path: 'renewalPeriodUnit',
          contentPath: 'controller.currentForm.selections.renewalUnits'
        }
      }, {
        label: 'Requires document',
        type: 'checkbox',
        path: 'requiresDocument'
      }, {
        displayIf: 'requiresDocument',
        label: 'Upload a document',
        autoTag: 'Certification',
        singleDoc: true,
        helpText: 'Uploading a document bypasses the need for the employee to upload their certification. This is useful if you already have a copy and need no action from the employee. If you would rather the employee upload their own document, leave this blank.',
        type: 'file',
        path: 'document'
      }],
      selections: {
        renewalUnits: ['days', 'months', 'years']
      }
    },
    'training assignment': {
      elements: [{
        label: 'Name',
        type: 'text',
        path: 'name'
      }, {
        label: 'Webinar (Optional)',
        type: 'select',
        path: 'webinar',
        contentPath: 'controller.dataDependencies.webinars',
        displayKey: 'webinar.title',
        helpLink: 'account.education.webinars',
        helpText: 'Get webinars...'
      }, {
        label: 'Notes',
        type: 'textarea',
        rows: '3',
        path: 'note'
      }, {
        label: 'Status',
        type: 'select',
        path: 'status',
        contentPath: 'controller.currentForm.selections.statuses'
      }, {
        displayIf: 'webinar',
        label: 'Due Date (Optional)',
        type: 'date',
        path: 'dueDate'
      }],
      selections: {
        statuses: ['Assigned', 'Completed']
      }
    },
    'certificationRenewal': {
      elements: [{
        label: 'Completed On',
        helpText: 'Specify date of renewal completion',
        type: 'date',
        path: 'completedOn'
      }, {
        label: 'Upload a document',
        autoTag: 'Certification',
        singleDoc: true,
        type: 'file',
        path: 'document'
      }]
    }
  };
  _exports.forms = forms;
});