define("granite/config/suggestions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formalActionSuggestions = void 0;
  var formalActionSuggestions = {
    issues: 'Be specific. Use clear example(s) of the behavior that needs to be corrected-not, "John is unprofessional with customers," but, "John raised his voice with a customer on this date, then was seen throwing his hands up into the air and walking away."',
    expectations: 'Set clear expectations. In the above example, you would want to include not just raising his voice to customers, but overall professionalism and courtesy. Beware of timelines; many behavioral issues are best addessed with something like: "We must see immediate and continued improvement in..."',
    consequences: '(Sample language, change to fit your needs)\n\rFailure to meet the above expectations may result in further disciplinary action, up to and including termination.'
  };
  _exports.formalActionSuggestions = formalActionSuggestions;
});