define("granite/components/drop-zone", ["exports", "ember-cli-dropzonejs/components/drop-zone"], function (_exports, _dropZone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dropZone.default;
    }
  });
});