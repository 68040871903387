define("granite/templates/account/employee/index/education/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nnWcN3xl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix animated fadeInUp\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"ui header titlecased text\"],[8],[0,\"\\n    Add a new \"],[1,[23,0,[\"type\"]],false],[0,\" for \"],[1,[23,0,[\"employee\",\"firstName\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[5,\"quick-form\",[],[[\"@form\",\"@model\",\"@controller\",\"@submitClass\",\"@onsubmit\"],[[23,0,[\"currentForm\",\"elements\"]],[23,0,[\"model\"]],[23,0,[]],\"large green fluid\",[28,\"fn\",[[23,0,[\"data\",\"saveRecord\"]],[23,0,[\"model\"]],\"working\",[23,0,[\"saveOptions\"]]],null]]],{\"statements\":[[0,\"\\n    Add \"],[1,[23,0,[\"type\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/education/add.hbs"
    }
  });

  _exports.default = _default;
});