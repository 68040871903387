define("granite/helpers/emoji-icon", ["exports", "ember-cli-emoji/helpers/emoji-icon"], function (_exports, _emojiIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emojiIcon.default;
    }
  });
  Object.defineProperty(_exports, "emojiIcon", {
    enumerable: true,
    get: function get() {
      return _emojiIcon.emojiIcon;
    }
  });
});