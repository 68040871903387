define("granite/templates/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sVs0Nq+2",
    "block": "{\"symbols\":[],\"statements\":[[5,\"elements/company-navigation\",[],[[\"@logout\"],[[23,0,[\"logout\"]]]]],[0,\"\\n\"],[5,\"display/bread-crumbs\",[[12,\"class\",\"account__breadcrumb\"]],[[\"@currentPath\",\"@overrides\"],[[23,0,[\"currentPath\"]],[23,0,[\"breadcrumbOverrides\"]]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account.hbs"
    }
  });

  _exports.default = _default;
});