define("granite/templates/account/first-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ecIuYd1y",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui hero cover grey overlay hero__first-steps-cover\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui center aligned container first-steps hero__content\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui text-white header first-steps__header\"],[8],[0,\"\\n      Welcome to Granite Human Resources\"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"small\",true],[8],[0,\"\\n        Once you complete these first steps, you'll see a company activity feed.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"first-steps__container \",[28,\"if\",[[23,0,[\"model\",\"company\",\"firstStepsCompletedOn\"]],\"first-steps__container--animating\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"company\",\"firstStepsCompletedOn\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"first-steps-container__message\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n            \"],[1,[28,\"emoji-icon\",[\"tada\"],null],false],[0,\" Great job completing the first steps! We'll take you to your activity feed now.\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\",true],[10,\"class\",\"ui clearing stackable three column grid\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"steps\"]]],null,{\"statements\":[[0,\"          \"],[5,\"display/first-steps\",[],[[\"@step\",\"@completed\"],[[23,1,[]],[28,\"contains\",[[23,1,[\"key\"]],[23,0,[\"model\",\"company\",\"firstStepsCompleted\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/first-steps.hbs"
    }
  });

  _exports.default = _default;
});