define("granite/templates/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l26jwySj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"contact__backplane animated fadeInUp \"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"contact-backplane__content ui very padded center aligned segment contact container\"],[8],[0,\"\\n    \"],[7,\"hgroup\",true],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"ui header\"],[8],[0,\"Questions about our product or pricing?\"],[9],[0,\"\\n      \"],[7,\"h3\",true],[10,\"class\",\"ui header\"],[8],[0,\"Let's get in touch.\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"ui section divider\"],[8],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"ui two column stackable padded grid\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n        \"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[7,\"i\",true],[10,\"class\",\"at icon\"],[8],[9],[0,\" By Email\"],[9],[0,\"\\n        \"],[7,\"a\",true],[10,\"href\",\"mailto:hello@granitehr.com\"],[10,\"class\",\"email-link\"],[8],[0,\"hello@granitehr.com\"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n        \"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[7,\"i\",true],[10,\"class\",\"call icon\"],[8],[9],[0,\" By Phone\"],[9],[0,\"\\n        \"],[7,\"a\",true],[10,\"id\",\"phone\"],[10,\"href\",\"tel:4062486178\"],[8],[0,\"+1 (406) 248-6178\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/contact.hbs"
    }
  });

  _exports.default = _default;
});