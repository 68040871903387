define("granite/templates/account/employees/forms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8pJVqtF2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix\"],[8],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"flex flex--space-between\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n      Forms & Responses\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"right aligned text menu\"],[8],[0,\"\\n      \"],[5,\"ui-dropdown\",[],[[\"@class\"],[\"item icon top right pointing\"]],{\"statements\":[[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"wrench icon\"],[8],[9],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n          \"],[5,\"link-to\",[[12,\"class\",\"item text-black\"]],[[\"@route\"],[\"account.settings.index.forms\"]],{\"statements\":[[0,\"Manage forms...\"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employees/forms.hbs"
    }
  });

  _exports.default = _default;
});