define("granite/templates/setup-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XMoI4swb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[12,\"class\",\"ui top-margin very padded piled text segment container form\"],[3,\"on\",[\"submit\",[28,\"prevent-default\",[[23,0,[\"activate\"]]],null]]],[8],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"ui center aligned header\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"header text-green\"],[8],[0,\"Good \"],[1,[22,\"relative-greeting-time\"],false],[0,\", \"],[1,[23,0,[\"model\",\"name\",\"first\"]],false],[0,\" \"],[1,[23,0,[\"model\",\"name\",\"last\"]],false],[0,\"!\"],[9],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"We need to finish your account activation by setting your password.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[5,\"input/ui-input\",[],[[\"@class\",\"@type\",\"@inputId\",\"@placeholder\",\"@value\"],[\"animated fadeInUp\",\"password\",\"user-password\",\"Enter a password\",[23,0,[\"password\"]]]]],[0,\"\\n  \"],[5,\"input/ui-input\",[],[[\"@class\",\"@type\",\"@inputId\",\"@placeholder\",\"@value\"],[\"animated fadeInUp\",\"password\",\"user-password-confirm\",\"Confirm your password\",[23,0,[\"passwordConfirm\"]]]]],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[5,\"form/action-button\",[[12,\"class\",\"ui massive fluid green button\"],[12,\"type\",\"submit\"]],[[\"@loading\",\"@disabled\"],[[23,0,[\"data\",\"statuses\",\"working\",\"isLoading\"]],[28,\"not\",[[23,0,[\"enableActivation\"]]],null]]],{\"statements\":[[0,\"Activate Your Account\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/setup-account.hbs"
    }
  });

  _exports.default = _default;
});