define("granite/templates/account/education/webinars/purchase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "taew+UzN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container webinars-purchase\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui active inverted dimmer\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui indeterminate massive text loader\"],[8],[0,\"\\n      One moment while we process your transaction.\\n      \"],[7,\"div\",true],[10,\"class\",\"smaller text\"],[8],[0,\"\\n        \"],[7,\"strong\",true],[8],[0,\"Do not navigate away from this page.\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/education/webinars/purchase.hbs"
    }
  });

  _exports.default = _default;
});