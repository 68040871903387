define("granite/templates/account/asset/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "taCCgSUa",
    "block": "{\"symbols\":[\"file\",\"execute\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"right aligned text floated menu__actions\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n    \"],[5,\"input/document-selection-modal\",[],[[\"@title\",\"@selected\",\"@autoTag\",\"@onSelected\"],[\"Assign asset documents\",[23,0,[\"model\",\"documents\"]],[23,0,[\"autoTag\"]],[28,\"pipe-action\",[[28,\"set\",[[23,0,[\"model\"]],\"documents\",[28,\"-set-placeholder\",null,null]],null],[28,\"fn\",[[23,0,[\"data\",\"saveRecord\"]],[23,0,[\"model\"]]],null]],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"on\",[\"click\",[28,\"prevent-default\",[[28,\"fn\",[[23,2,[]],\"selectDocuments\"],null]],null]]],[8],[0,\"\\n        \"],[5,\"ember-tooltip\",[],[[\"@side\",\"@popperContainer\",\"@text\"],[\"left\",\"body\",\"Add a document\"]]],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"plus icon\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui divided items\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\",\"documents\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"item\"],[8],[0,\"\\n      \"],[5,\"list-item/document-item\",[],[[\"@model\",\"@deleteAction\",\"@confirmDeleteContent\"],[[23,1,[]],[23,0,[\"removeDocument\"]],\"Are you sure you want to remove this document?\"]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"h2\",true],[10,\"class\",\"ui center aligned header\"],[8],[0,\"No documents available.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/asset/documents.hbs"
    }
  });

  _exports.default = _default;
});