define("granite/helpers/join-array", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.joinArray = joinArray;
  _exports.default = void 0;

  function joinArray(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        array = _ref2[0],
        _ref2$ = _ref2[1],
        separator = _ref2$ === void 0 ? ', ' : _ref2$;

    return array ? array.join(separator) : array;
  }

  var _default = Ember.Helper.helper(joinArray);

  _exports.default = _default;
});