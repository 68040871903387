define("granite/templates/account/job-opening/campaign/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XzKX6V67",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui very padded raised segment animated fadeInUp\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"ui clearfix header\"],[8],[0,\"\\n    Settings for \"],[1,[23,0,[\"model\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui secondary pointing menu\"],[8],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.job-opening.campaign.settings.index\"]],{\"statements\":[[0,\"Campaign\"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.job-opening.campaign.settings.screening\"]],{\"statements\":[[0,\"Screening\"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/job-opening/campaign/settings.hbs"
    }
  });

  _exports.default = _default;
});