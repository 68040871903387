define("granite/templates/components/list-item/change-item-rel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WaG4pNV4",
    "block": "{\"symbols\":[\"pathKey\",\"@item\",\"@change\",\"&attrs\"],\"statements\":[[7,\"div\",false],[13,4],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"on\",[\"click\",[28,\"prevent-default\",[[28,\"toggle\",[\"showDetails\",[23,0,[]]],null]],null]]],[8],[0,\"\\n    \"],[1,[28,\"humanize-key\",[[23,3,[\"path\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,3,[\"isArray\"]]],null,{\"statements\":[[0,\"      #\"],[1,[23,0,[\"index\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"ui caret \",[28,\"if\",[[23,0,[\"showDetails\"]],\"up\",\"down\"],null],\" icon\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"showDetails\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[\"pathKeys\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[8],[7,\"strong\",true],[8],[1,[28,\"humanize-key\",[[23,1,[]]],null],false],[9],[0,\": \"],[1,[28,\"get\",[[23,2,[]],[23,1,[]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/components/list-item/change-item-rel.hbs"
    }
  });

  _exports.default = _default;
});