define("granite/mixins/controller-abstractions/delete", ["exports", "granite/mixins/ajax-status"], function (_exports, _ajaxStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxStatus.default, {
    _afterDelete: function _afterDelete(record) {
      var transitionAfterSave = this.transitionAfterDelete || this.transitionAfterSave;

      if (transitionAfterSave) {
        var transitionArgs = [transitionAfterSave];

        if (this.transitionWithModel) {
          transitionArgs.push(record.get(this.getWithDefault('modelIdentifier', 'id')));
        }

        this.transitionToRoute.apply(this, transitionArgs);
      }
    },
    actions: {
      delete: function _delete(model) {
        var _this = this;

        console.warn('Mixing in the controller-abstraction for delete is deprecated in favor of extending the granite service: data.'); // eslint-disable-line

        var _model = model || this.model;

        if (!_model) {
          return;
        }

        this.ajaxStart();

        _model.destroyRecord().then(function (record) {
          _this.ajaxSuccess('Successfully deleted.');

          _this._afterDelete(record);

          if (_this.afterSave && typeof _this.afterSave === 'function') {
            _this.afterSave(record);
          }
        }).catch(this.ajaxError.bind(this));
      }
    }
  });

  _exports.default = _default;
});