define("granite/templates/account/benefits-paywall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/8bnI+j1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui hero cover grey overlay hero__bp\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"hero__content\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"ui header hero__title\"],[8],[0,\"\\n      Uh oh. It looks like you haven't unlocked this feature yet.\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"hero__subcontent\"],[8],[0,\"\\n      Here you will be able to manage the coverage available, add/edit custom benefits, and quickly view the plans\\n      available.\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"hero__subcontent\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        To access GraniteHR's Premium \\\"Benefit Integration\\\" feature, where you can bridge the gap of Benefits and employees.\\n      \"],[9],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"ui green button\"],[12,\"role\",\"button\"]],[[\"@route\"],[\"account.settings.billing\"]],{\"statements\":[[0,\"\\n        Unlock Benefits Administration\\n        \"],[7,\"i\",true],[10,\"class\",\"unlock alternate small icon\"],[8],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"text-center text-sm hero__subcontent\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"Back to index\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/benefits-paywall.hbs"
    }
  });

  _exports.default = _default;
});