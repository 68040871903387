define("granite/templates/account/action-item/discussion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6VgAbOg4",
    "block": "{\"symbols\":[\"comment\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui very padded raised segment animated fadeInUp\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui comments\"],[8],[0,\"\\n    \"],[7,\"form\",false],[12,\"class\",\"ui reply form\"],[3,\"on\",[\"submit\",[28,\"prevent-default\",[[23,0,[\"comment\"]]],null]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n        \"],[5,\"input/comment-box\",[],[[\"@value\"],[[23,0,[\"commentText\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"field right aligned text\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"ui blue right labeled submit icon button\"],[10,\"type\",\"submit\"],[8],[0,\"\\n          Comment\\n          \"],[7,\"i\",true],[10,\"class\",\"comment icon\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"h3\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Comments\"],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[5,\"list-item/discussion-comment-item\",[],[[\"@comment\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"h3\",true],[10,\"class\",\"center aligned text header\"],[8],[0,\"No comments available.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/action-item/discussion.hbs"
    }
  });

  _exports.default = _default;
});