define("granite/helpers/format-phone", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPhone = formatPhone;
  _exports.default = void 0;

  function formatPhone(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        phone = _ref2[0];

    return phone ? phone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : phone;
  }

  var _default = Ember.Helper.helper(formatPhone);

  _exports.default = _default;
});