define("granite/models/validations/company", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2
    })],
    contactPhone: [(0, _emberCpValidations.validator)('presence', true)],
    contactFirstName: [(0, _emberCpValidations.validator)('presence', true)],
    contactLastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    addressLine1: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2
    })],
    addressCity: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2
    })],
    addressState: [(0, _emberCpValidations.validator)('presence', true)],
    addressZipcode: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      is: 5
    })],
    urlPrefix: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 25
    }), (0, _emberCpValidations.validator)('urlprefix')]
  });

  _exports.default = _default;
});