define("granite/services/auth", ["exports", "moment", "granite/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Logger = Ember.Logger;

  var _default = Ember.Service.extend({
    authUrl: '/api/v1/login/company-user',
    clock: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    analytics: Ember.inject.service(),
    authenticated: Ember.computed.bool('token'),
    token: Ember.computed.reads('session.token'),
    userId: Ember.computed.reads('session.user'),
    initializeClock: Ember.on('init', function () {
      this.clock; // eslint-disable-line
    }),

    /**
     * Login intent
     * @param  {String} email    User email
     * @param  {String} password User password
     * @return {Promise}         Resolves to session
     */
    login: function login(email, password) {
      var _this = this;

      var data = {
        email: email,
        password: password
      };
      Logger.debug('AS :: Requesting authentication service', this.authUrl);
      return this.ajax.post(this.authUrl, {
        data: data
      }).then(function (response) {
        Logger.debug('AS :: Got server response', response);

        var session = _this.store.createRecord('session', {
          token: response.token,
          expires: response.expires,
          user: response.user,
          id: response.id
        });

        _this.analytics.identifyUser(response.user);

        _this.analytics.trackEvent('Session', 'log in', 'Session Login');

        return _environment.default.environment === 'test' ? Ember.RSVP.Promise.resolve(session) : session.save();
      }).then(function (record) {
        Logger.debug('AS :: Saved session record in localforage');

        _this.set('session', record);

        _this.currentUser; // eslint-disable-line

        return record;
      });
    },

    /**
     * Logout intent
     * @return {Promise} Resolves to destroyed session
     */
    logout: function logout() {
      var _this2 = this;

      Logger.debug('AS :: Logout');

      if (!this.session) {
        Logger.debug('AS :: No session available - skipping logout');
        return Ember.RSVP.Promise.resolve();
      }

      this.analytics.trackEvent('Session', 'logout', 'Session Logout');
      Logger.debug('AS :: Destroying session');
      return this.session.destroyRecord().then(function () {
        _this2.set('session', null);
      });
    },
    refreshSession: function refreshSession() {
      var _this3 = this;

      if (!this.authenticated) {
        return Ember.RSVP.Promise.resolve();
      }

      this.analytics.trackEvent('Session', 'refresh', 'Session Refreshed');
      return this.ajax.request('/api/v1/grant/' + this.get('session.id') + '/refresh', {
        method: 'POST'
      }).then(function (response) {
        var session = _this3.session;
        session.set('expires', response.expires);
        return session.save();
      });
    },

    /**
     * Initializes an existing session
     * @param  {Boolean} cleanup Cleans expirees as we get the session
     * @return {Promise}         Resolves to false or existing session
     */
    initializeExistingSession: function initializeExistingSession() {
      var _this4 = this;

      var cleanup = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      Logger.debug('AS :: Initializing existing session');
      return this.store.findAll('session').then(function (sessions) {
        var existingSession;
        Logger.debug('AS :: Got local sessions');
        sessions.toArray().forEach(function (session) {
          if ((0, _moment.default)(session.get('expires')).isAfter((0, _moment.default)())) {
            Logger.debug('AS :: Found active session');
            existingSession = session;
          } else if (cleanup) {
            session.destroyRecord();
          }
        });

        if (existingSession) {
          _this4.set('session', existingSession);
        }

        return existingSession ? _this4.user.then(function (user) {
          return user.get('employee');
        }).then(function () {
          return existingSession;
        }).catch(function (err) {
          if (((err || {}).errors || []).filter(function (e) {
            return e.status === '401';
          }).length > 0) {
            return _this4.logout();
          } else {
            throw err;
          }
        }) : false;
      });
    },
    user: Ember.computed('userId', function () {
      var userId = this.userId;

      if (!userId || !this.authenticated) {
        return Ember.RSVP.Promise.resolve();
      }

      return this.store.find('company-user', userId);
    }),
    isExpired: Ember.computed('clock.minute', 'session.expires', 'authenticated', function () {
      return this.authenticated && (0, _moment.default)(this.get('session.expires')).isBefore((0, _moment.default)());
    }),
    isExpiring: Ember.computed('clock.minute', 'session.expires', function () {
      return this.authenticated && (0, _moment.default)(this.get('session.expires')).subtract(10, 'minutes').isBefore((0, _moment.default)());
    })
  });

  _exports.default = _default;
});