define("granite/templates/account/employee/index/edit/tax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zHtnJ4i2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"ui horizontal header divider section\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"balance scale icon\"],[8],[9],[0,\"\\n  Tax Information\\n\"],[9],[0,\"\\n\"],[5,\"form/state-w4\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n\\n\"],[7,\"h3\",true],[10,\"class\",\"ui horizontal header divider section\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"university icon\"],[8],[9],[0,\"\\n  Federal Tax (from W-4)\\n\"],[9],[0,\"\\n\"],[5,\"form/w-4\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/edit/tax.hbs"
    }
  });

  _exports.default = _default;
});