define("granite/templates/account/anatomy/company-users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NpqHdvcg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[12,\"class\",\"ui form\"],[3,\"on\",[\"submit\",[28,\"prevent-default\",[[28,\"pipe\",[[23,0,[\"permissionCheck\"]],[28,\"fn\",[[23,0,[\"data\",\"saveRecord\"]],[23,0,[\"model\"]],\"working\",[23,0,[\"saveOptions\"]]],null]],null]],null]]],[8],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui header left floated\"],[8],[0,\"\\n      Editing \"],[1,[23,0,[\"model\",\"fullName\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"permission_label\"],[8],[0,\"Permissions\"],[9],[0,\"\\n  \"],[5,\"x-tree\",[],[[\"@checkable\",\"@model\"],[true,[23,0,[\"permissionsTree\"]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[5,\"form/action-button\",[[12,\"class\",\"ui huge fluid green button\"],[12,\"type\",\"submit\"]],[[\"@loading\"],[[23,0,[\"data\",\"statuses\",\"working\",\"isLoading\"]]]],{\"statements\":[[0,\"Save Changes\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/anatomy/company-users/edit.hbs"
    }
  });

  _exports.default = _default;
});