define("granite/helpers/long-text", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.longText = longText;
  _exports.default = void 0;

  function longText(_ref
  /*, hash*/
  ) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        text = _ref2[0];

    var body = Ember.Handlebars.Utils.escapeExpression(text);
    body = body.replace(/\n\r?/g, '<br>');
    return new Ember.String.htmlSafe(body);
  }

  var _default = Ember.Helper.helper(longText);

  _exports.default = _default;
});