define("granite/models/location", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships"], function (_exports, _emberData, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: (0, _attr.default)('string'),
    code: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    addressLine1: (0, _attr.default)('string'),
    addressLine2: (0, _attr.default)('string'),
    addressCity: (0, _attr.default)('string'),
    addressState: (0, _attr.default)('string'),
    addressZipcode: (0, _attr.default)('string'),
    company: (0, _relationships.belongsTo)('company'),
    creator: (0, _relationships.belongsTo)('company-user', {
      async: true,
      inverse: false
    }),
    phoneFormatted: Ember.computed('phone', function () {
      var phone = this.phone;
      return phone ? phone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : phone;
    }),
    created: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });

  _exports.default = _default;
});