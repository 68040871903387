define("granite/mixins/ajax-status", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Logger = Ember.Logger;

  function searchError(errors) {
    var detailKeys = Ember.A(['detail', 'message', 'title', 'status']),
        key = detailKeys.find(function (k) {
      return errors[0][k];
    });
    return key ? errors.mapBy(key).join(', ') : errors[0];
  }

  function findFieldErrors() {
    var errors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var fe = errors.reduce(function (fieldErrors, err) {
      if (err && err.path) {
        fieldErrors[err.path] = err.detail || err.title;
      }

      return fieldErrors;
    }, {});
    return Object.keys(fe).length > 0 ? fe : false;
  }

  var _default = Ember.Mixin.create({
    successMessageTimeout: 3,
    enableNotify: true,
    loadingProp: 'working',
    slowRunningThreshold: 500,
    ajaxError: function ajaxError(err, user) {
      var _this$setProperties;

      this.__cancelLongRunningProp();

      var errMsg = err ? err.payload || err.responseText || err.message || err : err,
          errorsArray = errMsg && errMsg.errors;

      if (errorsArray) {
        errMsg = typeof errMsg.errors[0] === 'string' ? errorsArray : searchError(errorsArray);
      }

      if (err && !user) {
        Logger.error(err.stack || err);
      }

      this.setProperties((_this$setProperties = {}, (0, _defineProperty2.default)(_this$setProperties, this.loadingProp, false), (0, _defineProperty2.default)(_this$setProperties, "".concat(this.loadingProp, "Slow"), false), (0, _defineProperty2.default)(_this$setProperties, "errorMessage", errMsg), (0, _defineProperty2.default)(_this$setProperties, "fieldErrors", errorsArray && findFieldErrors(errorsArray)), _this$setProperties));

      if (this.enableNotify) {
        this.send('notify', 'error', 'Whoops! ' + errMsg);
      }
    },
    ajaxSuccess: function ajaxSuccess(success, silent) {
      var _this$setProperties2,
          _this = this;

      this.__cancelLongRunningProp();

      this.setProperties((_this$setProperties2 = {}, (0, _defineProperty2.default)(_this$setProperties2, this.loadingProp, false), (0, _defineProperty2.default)(_this$setProperties2, "".concat(this.loadingProp, "Slow"), false), (0, _defineProperty2.default)(_this$setProperties2, "errorMessage", null), (0, _defineProperty2.default)(_this$setProperties2, "successMessage", success), (0, _defineProperty2.default)(_this$setProperties2, "fieldErrors", false), _this$setProperties2));
      setTimeout(function () {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.set('successMessage', null);
        }
      }, this.successMessageTimeout * 1000);

      if (!silent && this.enableNotify) {
        this.send('notify', 'success', success || 'Successfully saved.');
      }
    },
    ajaxStart: function ajaxStart() {
      var _this$setProperties3;

      this.setProperties((_this$setProperties3 = {}, (0, _defineProperty2.default)(_this$setProperties3, this.loadingProp, true), (0, _defineProperty2.default)(_this$setProperties3, "".concat(this.loadingProp, "Slow"), false), (0, _defineProperty2.default)(_this$setProperties3, "errorMessage", null), (0, _defineProperty2.default)(_this$setProperties3, "successMessage", null), (0, _defineProperty2.default)(_this$setProperties3, "fieldErrors", false), _this$setProperties3));

      this.__scheduleLongRunningProp();
    },
    __cancelLongRunningProp: function __cancelLongRunningProp() {
      var timeoutId = this.__longRunningPropToid;

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    },
    __scheduleLongRunningProp: function __scheduleLongRunningProp() {
      var _this2 = this;

      var loadingProp = this.loadingProp;
      this.set('__longRunningPropToid', setTimeout(function () {
        if (!_this2.isDestroyed && _this2.get(loadingProp)) {
          _this2.set("".concat(loadingProp, "Slow"), true);
        }
      }, this.slowRunningThreshold));
    }
  });

  _exports.default = _default;
});