define("granite/utils/uri-for-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = uriForModel;
  var urlMap = {
    employee: 'employee.index',
    companyuser: {
      uri: 'anatomy.company-users',
      id: false
    },
    department: {
      uri: 'anatomy.departments',
      id: false
    },
    location: {
      uri: 'anatomy.locations',
      id: false
    },
    jobopening: 'job-opening.campaign',
    actionitem: {
      uri: 'action-item',
      id: function id(m) {
        return (m._source.title || '').replace(/\s/g, '-');
      }
    }
  };

  function uriForModel() {
    var model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _id = model._id,
        _type = model._type,
        uriMapping = urlMap[_type];
    var args = ["account.".concat(uriMapping.uri || uriMapping)];

    if (uriMapping.id !== false) {
      var idMap = uriMapping.id,
          idArg = idMap && typeof idMap === 'function' ? idMap(model) : _id;
      args.push(idArg);
    }

    return args;
  }
});