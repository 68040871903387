define("granite/serializers/company", ["exports", "ember-data", "granite/serializers/application", "granite/utils/serialize-object", "granite/utils/expand-serialized-object"], function (_exports, _emberData, _application, _serializeObject, _expandSerializedObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      correctiveActionSeverities: {
        embedded: 'always'
      },
      labels: {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, hash) {
      if (modelClass.modelName === 'company') {
        (0, _serializeObject.default)(hash, true, 'address');

        if (hash.contact) {
          if (hash.contact.name) {
            hash.contactLastName = hash.contact.name.last;
            hash.contactFirstName = hash.contact.name.first;
            hash.contactMiddleName = hash.contact.name.middle;
          }

          hash.contactPhone = hash.contact.phone;
          hash.contactExtension = hash.contact.ext;
          delete hash.contact;
        }
      }

      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      (0, _expandSerializedObject.default)(json, 'address');
      json.contact = {};
      json.contact.name = {};
      json.contact.name.first = json.contactFirstName;
      json.contact.name.last = json.contactLastName;
      json.contact.name.middle = json.contactMiddleName;
      json.contact.phone = json.contactPhone;
      json.contact.ext = json.contactExtension;
      return json;
    }
  });

  _exports.default = _default;
});