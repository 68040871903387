define("granite/templates/account/assets/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tz3jc1Lr",
    "block": "{\"symbols\":[\"asset\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix\"],[8],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui header left floated\"],[8],[0,\"\\n      Company Assets\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"h3\",true],[8],[5,\"link-to\",[[12,\"class\",\"right floated\"]],[[\"@route\"],[\"account.assets.new\"]],{\"statements\":[[7,\"i\",true],[10,\"class\",\"plus icon\"],[8],[9]],\"parameters\":[]}],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divided link items\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\",\"@model\"],[\"account.asset\",[23,1,[]]]],{\"statements\":[[0,\"\\n        \"],[5,\"list-item/asset-item\",[],[[\"@asset\"],[[23,1,[]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"h2\",true],[10,\"class\",\"ui center aligned header\"],[8],[0,\"No assets available.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n  \"],[5,\"elements/x-pagination\",[],[[\"@page\",\"@model\",\"@limit\",\"@onChange\"],[[23,0,[\"page\"]],[23,0,[\"model\"]],[23,0,[\"limit\"]],[28,\"set\",[[23,0,[]],\"page\",[28,\"-set-placeholder\",null,null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/assets/index.hbs"
    }
  });

  _exports.default = _default;
});