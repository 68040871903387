define("granite/services/search", ["exports", "@babel/runtime/helpers/esm/extends"], function (_exports, _extends2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var resultDisplayMap = {
    employee: {
      title: ['name.first', 'name.middle', 'name.last'],
      description: 'jobTitle'
    },
    department: {
      title: 'name'
    },
    location: {
      title: 'name',
      description: ['address.line1', 'address.city', 'address.state']
    },
    companyuser: {
      title: ['name.first', 'name.middle', 'name.last'],
      description: 'email'
    },
    jobopening: {
      title: 'name',
      description: ['title', 'category']
    },
    actionitem: {
      title: 'title',
      description: 'description'
    }
  };
  var categoryMap = {
    employee: 'Employees',
    department: 'Departments',
    location: 'Locations',
    companyuser: 'Users',
    jobopening: 'Job Openings',
    actionitem: 'Projects'
  };

  var createSerializer = function createSerializer(serializerOptions) {
    return function (result) {
      var _ref = result || {},
          _type = _ref._type,
          _source = _ref._source,
          typeMap = _type && resultDisplayMap[_type];

      if (!typeMap || !_source) {
        return result;
      }

      Object.keys(typeMap).forEach(function (key) {
        var combineKeys = typeMap[key];

        if (!Array.isArray(combineKeys)) {
          combineKeys = [combineKeys];
        }

        result[key] = combineKeys.map(function (subKey) {
          return Ember.get(_source, subKey);
        }).filter(Boolean).join(' ');
      });

      if (serializerOptions.categorical) {
        result.category = categoryMap[_type];
      }

      if (serializerOptions.image && serializerOptions.image[_type]) {
        var imgDef = serializerOptions.image[_type],
            imgKey = imgDef.key || imgDef;
        result[imgKey] = result._source[imgKey] || imgDef.default(result) || null;
      }

      return result;
    };
  };

  var searchDefaults = {
    categorical: true,
    image: {
      employee: {
        key: 'picture',
        default: function _default(m) {
          return m._id ? "/api/v1/employee/".concat(m._id, "/avatar") : 'https://www.gravatar.com/avatar/?f=y&d=mp';
        }
      }
    }
  };

  var _default2 = Ember.Service.extend({
    ajax: Ember.inject.service(),
    fields: {
      title: 'title',
      description: 'description',
      image: 'picture'
    },
    performSearch: function performSearch(q) {
      var _this = this;

      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      (false && !(true) && Ember.assert(!!q, true, 'search#performSearch requires a query argument.'));
      var searchOptions = Object.assign({}, searchDefaults, opts);
      return this.ajax.request('/api/v1/search', {
        data: {
          q: q
        }
      }).then(function (response) {
        var responseCopy = (0, _extends2.default)({}, response);
        responseCopy.results = _this.serializeResults(response.results, searchOptions);
        return responseCopy;
      });
    },
    serializeResults: function serializeResults() {
      var results = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var mappedResults = results.map(createSerializer(options));

      if (options.categorical) {
        mappedResults = mappedResults.reduce(function (groups) {
          var result = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
          var category = result.category;

          if (!groups[category]) {
            groups[category] = {
              name: category,
              results: []
            };
          }

          groups[category].results.push(result);
          return groups;
        }, {});
      }

      return mappedResults;
    }
  });

  _exports.default = _default2;
});