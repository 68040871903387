define("granite/transforms/array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return Ember.typeOf(serialized) === 'array' ? serialized : [];
    },
    serialize: function serialize(deserialized) {
      var type = Ember.typeOf(deserialized);

      if (type === 'array') {
        return deserialized;
      } else if (type === 'string') {
        return deserialized.split(',').map(function (item) {
          return Ember.$.trim(item);
        });
      } else {
        return [];
      }
    }
  });

  _exports.default = _default;
});