define("granite/templates/account/employee/index/visual-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T2eDcnoU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix animated fadeInUp\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n    Submit A Visual ID\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"light text\"],[8],[0,\"\\n    To account for every employee in your company, the EEOC recommends visual survey for categories declined by the employee. Please complete a visual survey below.\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[5,\"quick-form\",[],[[\"@form\",\"@model\",\"@controller\",\"@submitClass\",\"@onsubmit\"],[[23,0,[\"visualIdForm\"]],[23,0,[\"visualId\"]],[23,0,[]],\"large fluid positive\",[23,0,[\"submitVisualId\"]]]],{\"statements\":[[0,\"\\n    Complete Visual ID \"],[7,\"i\",true],[10,\"class\",\"fitted check icon\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/visual-id.hbs"
    }
  });

  _exports.default = _default;
});