define("granite/templates/account/job-opening/campaign/eeo-reporting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wu4yWlHi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui very padded raised segment animated fadeInUp\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"ui clearfix header\"],[8],[0,\"\\n    EEO Reports\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[5,\"display/report-view\",[[12,\"class\",\"eeo-table\"]],[[\"@data\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/job-opening/campaign/eeo-reporting.hbs"
    }
  });

  _exports.default = _default;
});