define("granite/components/diff-value-translator", ["exports", "moment", "granite/models/employee"], function (_exports, _moment, _employee) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.computedValue.isPending}}
    <div class="ui active inline loader"></div>
  {{else}}
    {{#if this.isRelationship}}
      {{if this.displayKey (get this.computedValue this.displayKey) (to-string this.computedValue)}}
    {{else}}
      {{to-string this.computedValue}}
    {{/if}}
  {{/if}}
  
  */
  {
    id: "1Xo87mn1",
    block: "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"computedValue\",\"isPending\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui active inline loader\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isRelationship\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"if\",[[23,0,[\"displayKey\"]],[28,\"get\",[[23,0,[\"computedValue\"]],[23,0,[\"displayKey\"]]],null],[28,\"to-string\",[[23,0,[\"computedValue\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"to-string\",[[23,0,[\"computedValue\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "granite/components/diff-value-translator.hbs"
    }
  });
  /*
    Ported from slate-payroll with a big shoutout to the notorious J.S.C.
  */


  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: 'span',
    displayMap: {
      company: 'name',
      location: 'name',
      department: 'name',
      employee: 'fullName',
      'company-user': 'fullName'
    },
    store: Ember.inject.service(),
    isRelationship: Ember.computed.bool('coercionType.model'),
    displayKey: Ember.computed('displayMap', 'coercionType.model', function () {
      var model = this.get('coercionType.model');
      return model ? this.get('displayMap.' + model) : undefined;
    }),
    coercionType: Ember.computed(function () {
      var _this = this;

      var attributeKinds = Ember.get(_employee.default, 'fields'),
          changed = this.pathForChange,
          transformedAttrs = Ember.get(_employee.default, 'transformedAttributes');
      var coercion;
      attributeKinds.forEach(function (type, field) {
        if (changed === field) {
          var transform = transformedAttrs.get(field);
          coercion = {
            type: type,
            transform: transform
          };

          if (coercion.type === 'belongsTo') {
            coercion.model = Ember.get(_employee.default.typeForRelationship(field, _this.store), 'modelName');
          }
        }
      });
      return coercion;
    }),
    pathForChange: Ember.computed('key', function () {
      var changed = this.key;
      return changed.join('.');
    }),
    computedValue: Ember.computed('coercionType', 'pathForChange', function () {
      var value = this.value,
          coercionType = this.coercionType;

      if (!coercionType || !value) {
        return value;
      }

      if (coercionType.transform === 'date') {
        return (0, _moment.default)(value).format('M/D/YYYY');
      } else if (coercionType.type === 'belongsTo') {
        return this.store.findRecord(coercionType.model, value);
      } else {
        return value;
      }
    })
  }));

  _exports.default = _default;
});