define("granite/templates/account/employee/offboard/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CEnQDFa+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"credit card alternative icon\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    Removed from billing\\n    \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"\\n      This employee will be removed from your billing once you have completely offboarded the employee.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",[29,[\"ui huge positive fluid button \",[28,\"if\",[[23,0,[\"data\",\"statuses\",\"wizard\",\"isLoading\"]],\"loading\"],null]]]],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"route-action\",[\"startOffboarding\"],null]]],[8],[0,\"Let's get started\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/offboard/index.hbs"
    }
  });

  _exports.default = _default;
});