define("granite/templates/account/job-opening/campaign/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qh8REKxF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui very padded raised segment animated fadeInUp\"],[8],[0,\"\\n  \"],[5,\"recruiting-status-bar\",[],[[\"@campaign\"],[[23,0,[\"jobOpening\"]]]]],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n    Recent Activity\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[5,\"ember-chart\",[],[[\"@type\",\"@data\",\"@options\"],[\"line\",[23,0,[\"model\"]],[23,0,[\"summaryOpts\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h2\",true],[10,\"class\",\"light text center aligned\"],[8],[0,\"Not enough recent data to show your summary.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/job-opening/campaign/index.hbs"
    }
  });

  _exports.default = _default;
});