define("granite/templates/account/settings/billing/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tPwkk/h8",
    "block": "{\"symbols\":[\"transaction\",\"index\"],\"statements\":[[4,\"if\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui basic segment\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"Transactions\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"center aligned small light text\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"info circle icon\"],[8],[9],[0,\"\\n      We show transactions for the last year. If you need access to an earlier transaction, please contact support.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui container\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[5,\"list-item/invoice-payment-method\",[],[[\"@transaction\",\"@index\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"h3\",true],[8],[0,\"No transactions yet.\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/settings/billing/invoices.hbs"
    }
  });

  _exports.default = _default;
});