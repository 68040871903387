define("granite/templates/account/employees/messages/thread", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zaQKJmms",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"messaging__thread\"],[8],[0,\"\\n  \"],[5,\"messaging/message-thread\",[],[[\"@messages\",\"@thread\",\"@retrievalMax\",\"@onMessage\",\"@onScrollback\"],[[23,0,[\"model\",\"messages\"]],[23,0,[\"model\",\"thread\"]],[23,0,[\"retrievalMax\"]],[23,0,[\"sendMessage\"]],[23,0,[\"scrollback\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employees/messages/thread.hbs"
    }
  });

  _exports.default = _default;
});