define("granite/templates/account/employee/offboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N6lJ7t5Z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui container employee__offboarding\"],[8],[0,\"\\n  \"],[5,\"ui-progress\",[],[[\"@percent\",\"@class\"],[[23,0,[\"progress\"]],\"indicating employee__offboarding-progress\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"bar\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui padded segment employee__offboarding-segment\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui header\"],[8],[0,\"Offboarding \"],[1,[23,0,[\"model\",\"fullName\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui basic segment\"],[8],[0,\"\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"ui small fluid button\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"prevent-default\",[[28,\"route-action\",[\"cancelOffboard\"],null]],null]]],[8],[0,\"Cancel\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"display/form-steps\",[[12,\"class\",\"employee__offboarding-steps\"]],[[\"@steps\",\"@basePath\"],[[23,0,[\"steps\"]],[23,0,[\"basePath\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/offboard.hbs"
    }
  });

  _exports.default = _default;
});