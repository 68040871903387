define("granite/utils/fade-rgb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fadeRgb;

  function fadeRgb(_ref, opacity) {
    var r = _ref.r,
        g = _ref.g,
        b = _ref.b;
    return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(opacity, ")");
  }
});