define("granite/templates/account/job-description/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aUOCPgMj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix animated fadeInUp\"],[8],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui header left floated\"],[8],[0,\"\\n      Edit Job Description\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[5,\"quick-form\",[],[[\"@form\",\"@model\",\"@controller\",\"@working\",\"@submitClass\",\"@onsubmit\",\"@disabled\"],[[23,0,[\"form\"]],[23,0,[\"model\"]],[23,0,[]],[23,0,[\"data\",\"statuses\",\"savingJobDesc\",\"isLoading\"]],\"huge fluid green\",[28,\"fn\",[[23,0,[\"data\",\"saveRecord\"]],[23,0,[\"model\"]],\"savingJobDesc\",[28,\"hash\",null,[[\"transitionWithModel\",\"transitionAfterSave\"],[true,\"account.job-description\"]]]],null],[28,\"or\",[[28,\"not\",[[23,0,[\"model\",\"category\"]]],null],[28,\"not\",[[23,0,[\"model\",\"title\"]]],null]],null]]],{\"statements\":[[0,\"\\n    Save\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/job-description/edit.hbs"
    }
  });

  _exports.default = _default;
});