define("granite/serializers/corrective-action", ["exports", "ember-data", "granite/serializers/application", "granite/utils/serialize-object", "granite/utils/expand-serialized-object"], function (_exports, _emberData, _application, _serializeObject, _expandSerializedObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      followUps: {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, hash) {
      (0, _serializeObject.default)(hash, true, 'description');
      return this._super.apply(this, arguments);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      (0, _expandSerializedObject.default)(json, 'description');
      return json;
    }
  });

  _exports.default = _default;
});