define("granite/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute('account.asset.index.index'), this.toRoute('account.asset.index.new'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('account.anatomy.departments'), this.toRoute('account.anatomy.departments.new'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.fromRoute('account.anatomy.locations'), this.toRoute('account.anatomy.locations.new'), this.use('toUp'), this.reverse('toDown'));
  }
});