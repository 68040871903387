define("granite/templates/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "73GyNVFe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui hero cover grey overlay hero__401\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"hero__content\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"ui header hero__title\"],[8],[0,\"\\n      401 Unauthorized\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"hero__subcontent\"],[8],[0,\"\\n      You were unauthorized to access that resource.\"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"small\",true],[8],[0,\"\\n        \"],[7,\"code\",true],[8],[1,[23,0,[\"unauthorizedReason\"]],false],[9],[0,\" That's all we know.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"text-center text-sm hero__subcontent\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"Back to index\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/unauthorized.hbs"
    }
  });

  _exports.default = _default;
});