define("granite/instance-initializer/rsvp-error-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var rollbarService = appInstance.lookup('service:rollbar');
    Ember.RSVP.on('error', function (reason) {
      rollbarService.error(reason);
    });
  }

  var _default = {
    name: 'rsvp-error-handler',
    initialize: initialize
  };
  _exports.default = _default;
});