define("granite/templates/signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pUEPN702",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui splash splash__signup fluid container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui stackable splash splash__signup grid\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"three wide column\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui vertical signup steps animated fadeInRight\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"steps\"]]],null,{\"statements\":[[0,\"          \"],[5,\"display/ui-step\",[],[[\"@step\",\"@link\"],[[23,1,[]],false]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ten wide column\"],[8],[0,\"\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"three wide column\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/signup.hbs"
    }
  });

  _exports.default = _default;
});