define("granite/helpers/humanize-key", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "granite/utils/humanize-key-name"], function (_exports, _slicedToArray2, _humanizeKeyName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanizeKey = humanizeKey;
  _exports.default = void 0;

  function humanizeKey(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        str = _ref2[0];

    return (0, _humanizeKeyName.default)(str);
  }

  var _default = Ember.Helper.helper(humanizeKey);

  _exports.default = _default;
});