define("granite/utils/mul-rgb", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lighten = _exports.darken = void 0;

  var makeUtil = function makeUtil(pos) {
    return function (rgb, per) {
      var div = per / 100;

      var _Object$keys$map = Object.keys(rgb).map(function (k) {
        var v = rgb[k],
            p = v * div;
        v = Math.round(pos ? v + p : v - p);
        return v > 255 ? 255 : v < 0 ? 0 : v;
      }),
          _Object$keys$map2 = (0, _slicedToArray2.default)(_Object$keys$map, 3),
          r = _Object$keys$map2[0],
          g = _Object$keys$map2[1],
          b = _Object$keys$map2[2];

      return {
        r: r,
        g: g,
        b: b
      };
    };
  };

  var darken = makeUtil();
  _exports.darken = darken;
  var lighten = makeUtil(true);
  _exports.lighten = lighten;
});