define("granite/templates/account/employee/onboard/picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HUB5uZAE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[10,\"class\",\"ui horizontal divider header\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"photo icon\"],[8],[9],[0,\"Upload \"],[1,[23,0,[\"model\",\"firstName\"]],false],[0,\"'s Picture\\n\"],[9],[0,\"\\n\\n\"],[7,\"form\",false],[12,\"class\",\"ui form\"],[3,\"on\",[\"submit\",[28,\"prevent-default\",[[28,\"route-action\",[\"saveAndContinue\"],null]],null]]],[8],[0,\"\\n  \"],[5,\"drop-zone\",[],[[\"@id\",\"@class\",\"@url\",\"@uploadMultiple\",\"@maxFiles\",\"@addRemoveLinks\",\"@acceptedFiles\",\"@thumbnailWidth\",\"@dictDefaultMessage\",\"@headers\",\"@success\",\"@removedfile\",\"@capture\"],[\"input__dropzone--employee-profile-image\",\"input__dropzone\",[23,0,[\"pictureEndpoint\"]],false,1,true,\"image/*\",\"175\",\"Drop Files or Click to Add a Picture\",[28,\"hash\",null,[[\"X-API-Token\"],[[23,0,[\"auth\",\"token\"]]]]],[23,0,[\"uploadedFile\"]],[23,0,[\"removeFile\"]],\"camera\"]]],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[5,\"form/action-button\",[[12,\"class\",\"ui huge fluid green button\"],[12,\"type\",\"submit\"]],[[\"@loading\"],[[23,0,[\"data\",\"statuses\",\"wizard\",\"isLoading\"]]]],{\"statements\":[[0,\"Next\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/onboard/picture.hbs"
    }
  });

  _exports.default = _default;
});