define("granite/models/asset-item", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    identifier: (0, _attr.default)('string'),
    assignments: (0, _relationships.hasMany)('asset-assignment'),
    customFields: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    asset: (0, _relationships.belongsTo)('asset'),
    creator: (0, _relationships.belongsTo)('company-user'),
    company: (0, _relationships.belongsTo)('company'),
    documents: (0, _relationships.hasMany)('file', {
      inverse: null,
      async: true
    }),
    created: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    assetId: Ember.computed.reads('asset.id')
  });

  _exports.default = _default;
});