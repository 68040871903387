define("granite/mixins/controller-abstractions/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    limit: 10,
    __totalItems: Ember.computed.reads('model.meta.totalRecords'),
    __metadataTotalItems: Ember.computed.reads('metadata.totalRecords'),
    pages: Ember.computed('__totalItems', '__metadataTotalItems', 'limit', function () {
      console.warn('Using the pagination mixin is no longer valid. Change arguments used in x-pagination component.'); // eslint-disable-line

      var total = this.__totalItems || this.__metadataTotalItems || 0;
      return Math.ceil(total / this.limit);
    })
  });

  _exports.default = _default;
});