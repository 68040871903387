define("granite/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5hUQVwOj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"title\",true],[8],[1,[23,0,[\"model\",\"title\"]],false],[9],[0,\"\\n\"],[7,\"meta\",true],[10,\"name\",\"description\"],[11,\"content\",[23,0,[\"model\",\"description\"]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/head.hbs"
    }
  });

  _exports.default = _default;
});