define("granite/templates/account/education", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0u5Rohf7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui container basic segment\"],[8],[0,\"\\n  \"],[5,\"ui-menu\",[],[[\"@menuClass\"],[\"pointing menu__education-page\"]],{\"statements\":[[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.education.index\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"list icon\"],[8],[9],[0,\"Summary\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.education.webinars\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"video icon\"],[8],[9],[0,\"Webinars\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/education.hbs"
    }
  });

  _exports.default = _default;
});