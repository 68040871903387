define("granite/utils/tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildTree = buildTree;
  _exports.getDescendents = getDescendents;
  _exports.getAncestors = getAncestors;
  _exports.getParent = getParent;

  /* Build a tree (nested objects) from a plain array
   * using `id` and `parentId` as references for the
   * relationships. The `name` property is expected
   * for rendering. Optionally, `valueKey` can be
   * passed for `id` and `labelKey` for `name`.
   * If the model is flat, it will return a list.
   */
  function buildTree(model) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var tree = {};
    var roots = Ember.A();

    if (Ember.isEmpty(model)) {
      return roots;
    } // Set defaults and add all nodes to tree


    model.forEach(function (node) {
      // Alternative name for `id`
      if (options.valueKey) {
        Ember.set(node, 'id', Ember.get(node, options.valueKey));
      } // Alternative name for `name`


      if (options.labelKey) {
        Ember.set(node, 'name', Ember.get(node, options.labelKey));
      } // Defaults


      Ember.set(node, 'children', Ember.A());
      Ember.set(node, 'isVisible', Ember.get(node, 'isVisible') || true);
      Ember.set(node, 'isExpanded', Ember.get(node, 'isExpanded') || false);
      tree[Ember.get(node, 'id')] = node;
    }); // Connect all children to their parent

    model.forEach(function (node) {
      var child = tree[Ember.get(node, options.valueKey || 'id')];
      var parent = Ember.get(node, 'parentId');

      if (Ember.isEmpty(parent)) {
        roots.pushObject(child);
      } else {
        Ember.get(tree[parent], 'children').pushObject(child);
      }
    });
    return roots;
  } // Gets all descendents of a tree (array)
  // Returns a flat list of all descenents, including the top level of the tree


  function getDescendents(tree) {
    var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
    var descendents = Ember.A();

    if (depth < 0) {
      // Unlimited depth
      tree.forEach(function (node) {
        descendents.pushObject(node);
        descendents.pushObjects(getDescendents(node.children));
      });
    } else if (depth > 0) {
      tree.forEach(function (node) {
        descendents.pushObject(node);
        descendents.pushObjects(getDescendents(node.children, depth - 1));
      });
    }

    return descendents;
  } // Gets all ancestors of a childNode given a tree (array)
  // Returns a flat list of ancestors, including the childNode


  function getAncestors(tree, childNode) {
    var ancestors = Ember.A();
    var childId = childNode.get('id');
    tree.forEach(function (node) {
      if (!ancestors.isAny('id', childId)) {
        if (node.id === childId) {
          ancestors.pushObject(node);
        } else if (node.children.length > 0) {
          ancestors.pushObjects(getAncestors(node.children, childNode));

          if (ancestors.length > 0) {
            ancestors.pushObject(node);
          }
        }
      }
    });
    return ancestors;
  } // Gets the direct parent of a childNode given a flat list
  // Returns single object (parent) or undefined


  function getParent(list, childNode) {
    return list.find(function (x) {
      return x.children.find(function (y) {
        return y.id === childNode.get('id');
      });
    });
  }
});