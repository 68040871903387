define("granite/transforms/string-or-null", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var valOrUndefined = function valOrUndefined(val) {
    return !val || val === '' ? undefined : val;
  };

  var _default = _emberData.default.Transform.extend({
    deserialize: valOrUndefined,
    serialize: valOrUndefined
  });

  _exports.default = _default;
});