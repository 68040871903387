define("granite/helpers/x-inc", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.xInc = xInc;
  _exports.default = void 0;

  function xInc(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        _ref2$ = _ref2[0],
        val = _ref2$ === void 0 ? 0 : _ref2$,
        _ref2$2 = _ref2[1],
        inc = _ref2$2 === void 0 ? 1 : _ref2$2;

    return val + inc;
  }

  var _default = Ember.Helper.helper(xInc);

  _exports.default = _default;
});