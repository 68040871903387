define("granite/utils/serialize-object", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeObject;

  function upperCaseFirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function serializeObject(object, prefix) {
    var args = Array.prototype.slice.call(arguments, 2);

    var pre = function pre(a, key) {
      return prefix === 'suffix' ? key + upperCaseFirst(a) : a + upperCaseFirst(key);
    };

    if (!object || (0, _typeof2.default)(object) !== 'object') {
      return object;
    }

    var setInside = function setInside(o, a) {
      for (var key in o) {
        if (!Object.prototype.hasOwnProperty.call(o, key)) {
          continue;
        }

        if ((0, _typeof2.default)(o[key]) === 'object') {
          setInside(o[key], a ? pre(a, key) : key);
          continue;
        }

        var modifiedKey = a ? pre(a, key) : key;
        object[modifiedKey] = o[key];
      }

      return object;
    };

    args.forEach(function (objectKey) {
      if (!object[objectKey] || (0, _typeof2.default)(object[objectKey]) !== 'object') {
        return;
      }

      setInside(object[objectKey], prefix ? objectKey : null);
      delete object[objectKey];
    });
    return object;
  }
});