define("granite/templates/account/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yLoKUFrA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui secondary pointing menu\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.settings.index.index\"]],{\"statements\":[[0,\"Account\"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.settings.index.processes\"]],{\"statements\":[[0,\"Processes\"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.settings.index.features\"]],{\"statements\":[[0,\"Features\"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.settings.index.lists\"]],{\"statements\":[[0,\"Lists\"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.settings.index.forms\"]],{\"statements\":[[0,\"Forms\"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"item\"]],[[\"@route\"],[\"account.settings.index.exports\"]],{\"statements\":[[0,\"Exports\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/settings/index.hbs"
    }
  });

  _exports.default = _default;
});