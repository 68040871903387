define("granite/templates/components/list-item/history-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S+2xQSw3",
    "block": "{\"symbols\":[\"diff\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ui light horizontal divider header\"],[8],[0,\"\\n  \"],[1,[23,0,[\"history\",\"creator\",\"fullName\"]],false],[0,\"\\n  made\\n  \"],[1,[23,0,[\"history\",\"diff\",\"length\"]],false],[0,\" \"],[1,[28,\"inflect-text\",[\"changes\",[23,0,[\"history\",\"diff\",\"length\"]]],null],false],[0,\"\\n  at \"],[1,[28,\"time\",[[23,0,[\"history\",\"created\"]],\"h:mma\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui divided list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"history\",\"diff\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"item\",\"rhs\",\"rehired\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n        \"],[7,\"strong\",true],[8],[0,\"Rehired\"],[9],[0,\"\\n        on\\n        \"],[7,\"span\",true],[10,\"class\",\"ui green label\"],[8],[1,[28,\"time\",[[23,1,[\"item\",\"rhs\",\"rehired\"]]],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n        \"],[7,\"strong\",true],[8],[1,[28,\"humanize-key\",[[28,\"join-array\",[[23,1,[\"path\"]],\".\"],null]],null],false],[9],[0,\"\\n        changed from\\n        \"],[7,\"span\",true],[10,\"class\",\"ui label\"],[8],[5,\"diff-value-translator\",[],[[\"@key\",\"@value\"],[[23,1,[\"path\"]],[23,1,[\"lhs\"]]]]],[9],[0,\" to\\n        \"],[7,\"span\",true],[10,\"class\",\"ui green label\"],[8],[5,\"diff-value-translator\",[],[[\"@key\",\"@value\"],[[23,1,[\"path\"]],[23,1,[\"rhs\"]]]]],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/components/list-item/history-item.hbs"
    }
  });

  _exports.default = _default;
});