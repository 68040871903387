define("granite/helpers/time", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function time(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        date = _ref2[0],
        _ref2$ = _ref2[1],
        format = _ref2$ === void 0 ? 'M/D/YY' : _ref2$;

    return date ? (0, _moment.default)(date).format(format) : 'N/A';
  }

  var _default = Ember.Helper.helper(time);

  _exports.default = _default;
});