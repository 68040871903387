define("granite/templates/account/employee/index/counseling/issue/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tZy/LKjz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"hgroup\",true],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"right aligned text\"],[8],[0,\"If you need assistance call: \"],[7,\"span\",true],[10,\"class\",\"strong\"],[8],[7,\"a\",true],[10,\"href\",\"tel:(406)248-6178\"],[8],[0,\"(406) 248-6178\"],[9],[9],[9],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n    New Corrective Action For Issue\\n    \"],[5,\"link-to\",[[12,\"class\",\"right floated text text-danger\"]],[[\"@route\"],[\"account.employee.index.counseling.issue\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"remove icon\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n\"],[5,\"quick-form\",[],[[\"@form\",\"@model\",\"@controller\",\"@tagName\"],[[23,0,[\"form\"]],[23,0,[\"model\"]],[23,0,[]],\"div\"]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\"],[7,\"form\",false],[3,\"on\",[\"submit\",[28,\"prevent-default\",[[28,\"fn\",[[23,0,[\"data\",\"saveRecord\"]],[23,0,[\"model\"]],\"working\",[23,0,[\"saveOptions\"]]],null]],null]]],[8],[0,\"\\n  \"],[5,\"form/action-button\",[[12,\"class\",\"ui positive fluid huge button\"],[12,\"type\",\"submit\"]],[[\"@loading\"],[[23,0,[\"data\",\"statuses\",\"working\",\"isLoading\"]]]],{\"statements\":[[0,\"Save Corrective Action\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/counseling/issue/new.hbs"
    }
  });

  _exports.default = _default;
});