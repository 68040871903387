define("granite/templates/account/help/topic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eEgRx24V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix\"],[8],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\"],[\"account.help.index\"]],{\"statements\":[[0,\"\\n    Back to knowledgebase\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui header left floated\"],[8],[0,\"\\n      \"],[1,[23,0,[\"model\",\"title\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\"],[0,\"  \"],[1,[23,0,[\"model\",\"body\"]],true],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/help/topic.hbs"
    }
  });

  _exports.default = _default;
});