define("granite/helpers/inflect-text", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-inflector"], function (_exports, _slicedToArray2, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inflectText = inflectText;
  _exports.default = void 0;

  function inflectText(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        text = _ref2[0],
        length = _ref2[1];

    return length && length === 1 ? (0, _emberInflector.singularize)(text) : (0, _emberInflector.pluralize)(text);
  }

  var _default = Ember.Helper.helper(inflectText);
  /*
    Usage
    ---
    You have {{employees.length}} {{inflect-text 'Employee' employees.length}}!
      -> Given employees.length is 0 or >1
      "You have 0 Employees!"
      -> Given employees.length is 1
      "You have 1 Employee!"
  
    Note: Preserves case
   */


  _exports.default = _default;
});