define("granite/helpers/if-else-val", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ifElseVal = ifElseVal;
  _exports.default = void 0;

  function ifElseVal(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        val = _ref2[0],
        otherwise = _ref2[1];

    return val !== undefined && val !== null ? val : otherwise || 'Not provided';
  }

  var _default = Ember.Helper.helper(ifElseVal);

  _exports.default = _default;
});