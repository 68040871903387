define("granite/routes/account/settings/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var parentModel = this._super.apply(this, arguments),
          def = (parentModel.definitions || Ember.A()).toArray(),
          templates = parentModel.templates || Ember.A();

      return Object.assign({}, parentModel, {
        definitionsGrouped: def.reduce(function (groups, definition) {
          groups.pushObject({
            definition: definition,
            category: definition.get('category'),
            template: templates.filter(function (t) {
              return t.get('key') === definition.get('key');
            })[0]
          });
          return groups;
        }, Ember.A())
      });
    }
  });

  _exports.default = _default;
});