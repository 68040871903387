define("granite/services/socket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),
    connectedUsers: Ember.A(),
    initialize: function initialize() {
      if (this.socketIsInitialized) {
        return;
      }

      var socket = io({
        query: {
          user: this.get('auth.user.employee.id')
        }
      });
      this.setProperties({
        socket: socket,
        socketIsInitialized: true
      });

      this.__bindEvents(socket);
    },
    disconnect: function disconnect() {
      this.socket.disconnect();
      this.setProperties({
        socket: null,
        socketIsInitialized: false
      });
    },
    emit: function emit(event, data) {
      var socket = this.socket;
      socket.emit(event, data);
    },
    emitAndListen: function emitAndListen(event, data, listenEvent) {
      this.emit(event, data);
      return this.listen(listenEvent);
    },
    listen: function listen() {
      var _this = this;

      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var listenersKey = "__".concat(event.replace(' ', '_'), "_listeners");

      if (!this.get(listenersKey)) {
        this.set(listenersKey, Ember.A());
      }

      return new Ember.RSVP.Promise(function (resolve) {
        _this.get(listenersKey).push(resolve);
      });
    },
    on: function on(event, fn) {
      this.socket.on(event, fn);
    },
    __internalEmit: function __internalEmit() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      var method = this.get("on".concat(Ember.String.camelize(event)));

      if (method) {
        method(args);
      }

      var listenersKey = "__".concat(event.replace(' ', '_'), "_listeners"),
          listeners = this.get(listenersKey);
      (listeners || Ember.A()).forEach(function (resolvePromise) {
        return resolvePromise(args);
      });
      this.set(listenersKey, Ember.A());
    },
    __bindEvents: function __bindEvents(socket) {
      var _this2 = this;

      socket.on('thread_history', function (messages) {
        _this2.__internalEmit('thread_history', messages);
      });
      socket.on('user_list', function (users) {
        _this2.__internalEmit('user_list', users);

        _this2.set('connectedUsers', Ember.A(users));
      });
      socket.on('user_connect', function (user) {
        _this2.__internalEmit('user_connect', user);

        _this2.connectedUsers.addObject(user);
      });
      socket.on('user_disconnect', function (user) {
        _this2.__internalEmit('user_disconnect', user);

        _this2.connectedUsers.removeObject(user);
      });
    }
  });

  _exports.default = _default;
});